import React from 'react';
import styles from './CaseItem.module.scss';
import { Link } from '@reach/router';
import { CIRCLE_MODE } from '../../../../utils/constants';
import caseIcon from '../../../../images/icons/clipboard.svg';

const CaseItem = ({ caseItem, fromMyCases }) => {

    const getModeLabel = mode => {
        switch (mode) {
            case CIRCLE_MODE.restorative:
                return 'RES';
            case CIRCLE_MODE.mediation:
                return 'MED';
            case CIRCLE_MODE.mentoring:
                return 'MEN';
            default:
                return null;
        }
    }

    return (
        <Link
            to={fromMyCases ? `/cases/${caseItem.id}` : caseItem.id}
            className={`${styles.case} ${caseItem.archived ? styles.caseArchived : ''}`}>
            <div className={styles.caseWrapper}>
                <div className={styles.caseHeader}>
                    {caseItem.status === -1 && <span className={`label label-muted ${styles.caseHeaderDraft}`}>Draft</span>}
                    <img className={styles.caseHeaderIcon} src={caseIcon} alt='caseIcon' />
                    {caseItem.mode && <span className={`label label-primary  ${styles.caseHeaderMode}`}>{getModeLabel(caseItem.mode)}</span>}
                </div>
                {caseItem.leader && <p className='text-muted small'>{`${caseItem.leader.user.firstName} ${caseItem.leader.user.lastName} `}</p>}
                <p className='my-0'>{caseItem.name}</p>
                <p className='mb-0 bold mt-auto text-muted-2'>{caseItem.caseNumber}</p>
            </div>
        </Link>
    );
}

export default CaseItem;