import React from 'react';

const ErrorPage = () => {
    return (
        <div className='row'>
            <div className="col-md-6 mx-auto text-center p-3">
                <h3 className='mt-3 pt-3'>Oops, there was an error</h3>
                <a className='btn' href="/">
                    Back to dashboard
                </a>
            </div>

        </div>
    );
};
export default ErrorPage;