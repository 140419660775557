import React, { useState } from "react";
import { Dropdown } from '../../../components/Dropdown';
import { useMutation } from "@apollo/react-hooks";
import { DELETE_TRAINING_EVENT, GET_TRAINING_EVENTS } from "../services/graphql";
import Modal from '../../../components/Modal'
import moment from "moment";
import styles from '../../../components/MembershipItem/MembershipItem.module.scss';
import trStyles from '../Training.module.scss';
import cx from 'classnames';
import FormErrors from "../../../components/FormErrors";

const TrainingItem = ({ item, setItemToEdit, setItemView, organizationId, currentUser }) => {

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const now = moment().startOf('day');

    const [deleteTrainingEvent, { loading }] = useMutation(DELETE_TRAINING_EVENT, {
        onCompleted: data => {
            if (data.deleteTrainingEvent.errors && data.deleteTrainingEvent.errors.length > 0) {
                setFormErrors(data.deleteTrainingEvent.errors);
                return;
            }
            if (data.deleteTrainingEvent?.deleted) {
                setConfirmDelete(false);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TRAINING_EVENTS, variables: { organizationId }, fetchPolicy: 'network-only' }]
    });

    return (
        <>
            <li>
                <div className={styles.user}>
                    <button onClick={() => setItemView(item)} className={cx(trStyles.viewCourse, styles.userMain)}>
                        <div>
                            <h4 className={styles.userTitle}>
                                {item.title}
                            </h4>
                            <p className="small">{moment(item.eventDate).format('DD MMMM YYYY')} @ {item.eventTime}, {item.location}</p>
                        </div>
                    </button>
                    {(currentUser.isOrganizationAdmin || currentUser.officeMemberships?.find(om => om.isAdmin)) &&
                        <>
                            {moment(item.eventDate).isSameOrAfter(now) && (
                                <Dropdown classNames={styles.leaderActions}>
                                    <li><button onClick={() => setItemToEdit(item)}>Edit</button></li>
                                    <li><button onClick={() => setConfirmDelete(true)}>Delete</button></li>
                                </Dropdown>
                            )}
                        </>}
                </div>
            </li>
            {confirmDelete && (
                <Modal closeModal={() => setConfirmDelete(false)}>
                    <div className="text-center">
                        <h3 className="mb-3">Are you sure?</h3>
                        {formErrors && <FormErrors errors={formErrors} />}
                        <button
                            disabled={loading}
                            onClick={() => deleteTrainingEvent({
                                variables: {
                                    trainingEventId: item.id
                                }
                            })}
                            className="btn mr-1"
                        >Yes</button>
                        <button onClick={() => setConfirmDelete(false)} className="btn btn-muted">No</button>
                    </div>
                </Modal>
            )}
        </>
    )
}
export default TrainingItem;