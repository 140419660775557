import React, { useContext, useState } from "react";
import { AuthContext } from "../../services/auth/AuthProvider";
import { ArrowRight, Edit } from "react-feather";
import { Link } from '@reach/router';
import { useQuery } from "@apollo/react-hooks";
import styles from './Offices.module.scss';
import SideModal from '../../components/SideModal';
import EditOffice from "./EditOffice";
import { GET_OFFICES } from "./services/graphql";

const Offices = () => {

    const { currentUser } = useContext(AuthContext);

    const [editOffice, setEditOffice] = useState(null);

    const { data, loading, error } = useQuery(GET_OFFICES, {
        variables: {
            organizationId: currentUser?.organization?.id
        }
    })

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading...</p>
    }
    if (data?.organization) {
        const { organization } = data;

        return (
            <>
                <div className="card card-blue">
                    <div className="card-body">

                        <ul className="list-group">
                            {organization?.offices?.map(office =>
                                <li key={`view-${office.id}`} className="list-item list-item--separator justify-content-between">
                                    <p><Link className="link-reverse" to={`/offices/${office.id}`}>{office.name}</Link></p>
                                    <div className="d-flex align-items-center">
                                        <Link to={`/offices/${office.id}`} className={styles.iconBtn}><ArrowRight size={16} /></Link>
                                        <button onClick={() => setEditOffice(office)} className={`${styles.iconBtn} ${'ml-1'}`}><Edit size={14} /></button>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>

                </div>
                {editOffice && (
                    <SideModal closeModal={() => setEditOffice(null)} >
                        <EditOffice close={() => setEditOffice(null)} office={editOffice} />
                    </SideModal>
                )}
            </>
        )
    }
    return null
}

export default Offices