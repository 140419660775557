import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import FormErrors from '../../../components/FormErrors';

const LoginInit = ({ loginInit, loggingInit, formInitErrors }) => {

    const schema = Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });
    const onSubmit = data => {
        const { email } = data;
        loginInit({
            variables: {
                email
            }
        });
    }

    return (
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-form__group">
                <input
                    name="email"
                    placeholder="Your email"
                    className="basic-form__text-box"
                    ref={register}
                />
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
            </div>
            {formInitErrors && <FormErrors errors={formInitErrors} />}
            <button disabled={loggingInit} type="submit" className="btn btn-fullWidth u-display-block">{loggingInit ? 'Loading...' : 'Login'}</button>
        </form>
    );
}

export default LoginInit;
