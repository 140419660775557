import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from 'react-hook-form';
import FormErrors from "../../components/FormErrors";
import { EDIT_OFFICE } from "./services/graphql";

const EditOffice = ({ close, office }) => {

    const [formErrors, setFormErrors] = useState([]);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit'
    });

    const [editOffice, { loading }] = useMutation(EDIT_OFFICE, {
        update: (_, { data }) => {
            if (data?.editOffice?.errors?.length > 0) {
                setFormErrors(data.editOffice.errors)
                return
            }
            if (data?.editOffice?.office?.id) {
                close()
            }
        },
      //  refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId } }]
    })

    const onSubmit = data => {
        const { name } = data;
        editOffice({
            variables: {
                name,
                officeId: office.id
            }
        });

    }
    return (
        <>
            <h1>Edit office</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <label htmlFor="name">Office name</label>
                    <input
                        name="name"
                        defaultValue={office.name}
                        className="basic-form__text-box"
                        ref={register({ required: 'Please enter name' })}
                    />
                    {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <div className="mt-3">
                    <button type='submit' disabled={loading} className="btn">{loading ? 'Saving...' : 'Save'}</button>
                    <button type='button' onClick={close} className="btn btn-outline ml-1">Cancel</button>
                </div>
            </form>
        </>
    )
}

export default EditOffice;