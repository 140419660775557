import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_OFFICE_USERS } from "./services/graphql";
import Loader from "../../components/Loader";

const OfficeTeam = ({ officeId }) => {

    const { data, loading, error } = useQuery(GET_OFFICE_USERS, {
        variables: {
            officeId
        }
    })

    const renderClStatus = (user, office) => {
        const officeMembership = user.officeMemberships.find(om => om?.office?.id === office.id);
        if (officeMembership && officeMembership.isCircleLeader) {
            return <span className="label label-green ml-1">CL</span>
        }
        return null
    }

    const renderAdminStatus = (user, office) => {
        const officeMembership = user.officeMemberships.find(om => om?.office?.id === office.id);
        if (officeMembership && officeMembership.isAdmin) {
            return <span className="label label-primary">Admin</span>
        }
        return null
    }

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.office?.id) {

        const { office } = data;
        return (
            <>
                <div className="d-flex mb-3 align-items-center">
                    <h1>Team</h1>
                </div>
                {office.users.length > 0 ?
                    <ul className="list-group">
                        {office.users.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(user => (
                            <li className="list-item list-item--separator" key={user.id}>
                                <div className=" d-flex justify-content-between width-full">
                                    <p>{user.firstName} {user.lastName} {renderClStatus(user, office)}</p>
                                    <span>
                                        {renderAdminStatus(user, office)}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul> : <p>No office users</p>
                }
            </>
        )
    }
    return null
}

export default OfficeTeam;