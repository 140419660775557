import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
);

const Bars = ({ monthHours }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Hours',
                data: monthHours.map(mh => mh.hours),
                backgroundColor: 'rgba(243, 204, 189, 1)',
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
                barThickness: 30
            },
        ],
    };

    return <Bar height={100} options={options} data={data} />
}

export default Bars;