import React from "react";
import { CIRCLE_STATUS } from "../../../../../../utils/constants";
import { CheckCircle, Clock, XCircle } from "react-feather";

const Outcomes = ({ circle }) => {


    return (
        <>
            <h1>Desired outcomes {circle.finalAssessment && (circle.finalAssessment.justiceDone ? 'Reached' : 'Not Reached')}</h1>
            <p className="mb-3">{circle.desiredOutcome}</p>

            {circle.status < CIRCLE_STATUS.closed_pending_assessment ?
                <div className="d-flex align-items-center">
                    <Clock className="mr-1" />
                    <p className="bold">
                        Waiting for Agreement phase to complete
                    </p>
                </div> :
                <>
                    {!circle.finalAssessment ?
                        <div className="d-flex align-items-center">
                            <Clock className="mr-1" />
                            <p className="bold">Waiting for Case Leader to complete the outcomes report</p>
                        </div> :
                        <>
                            {/* <div className="d-flex align-items-center mb-1 pb-1">
                                {circle.finalAssessment.justiceDone ? <CheckCircle /> : <XCircle />}
                                <p className="bold ml-1">Justice has been done</p>
                            </div> */}
                            <div className="d-flex align-items-center mb-1 pb-1">
                                {circle.finalAssessment.terminatedEarly ? <CheckCircle /> : <XCircle />}
                                <p className="bold ml-1">{!circle.finalAssessment.terminatedEarly ? 'Circle ran the full session length' : 'Circle terminated early'}</p>
                            </div>
                            <div className="d-flex align-items-center mb-1 pb-1">
                                {circle.finalAssessment.success ? <CheckCircle /> : <XCircle />}
                                <p className="bold ml-1">{circle.finalAssessment.success ? 'Circle completed successfully' : 'Circle did not complete successfully'}</p>
                            </div>
                            <div className="d-flex align-items-center mb-1 pb-1">
                                {circle.finalAssessment.responsibleShowRemorse ? <CheckCircle /> : <XCircle />}
                                <p className="bold ml-1">{circle.finalAssessment.responsibleShowRemorse ? 'All responsible parties take responsibiliy and show regret' : 'Not all responsible parties take responsibiliy and show regret'} </p>
                            </div>
                            <div className="d-flex align-items-center mb-1 pb-1">
                                {circle.finalAssessment.responsibleRepaired ? <CheckCircle /> : <XCircle />}
                                <p className="bold ml-1">{circle.finalAssessment.responsibleRepaired ? 'All responsible parties made effective reparations' : 'Not all responsible parties made effective reparations'}</p>
                            </div>
                            <div className="d-flex align-items-center mb-1 pb-1">
                                {circle.finalAssessment.affectedPartySatisfied ? <CheckCircle /> : <XCircle />}
                                <p className="bold ml-1">{circle.finalAssessment.affectedPartySatisfied ? 'All affected parties are satisifed with the process' : 'Not all affected parties are satisifed with the process'} </p>
                            </div>
                        </>
                    }
                </>
            }

        </>
    )
}

export default Outcomes;