import { gql } from "apollo-boost";

export const ADD_MEMBER = gql`
mutation addMember($firstName: String, $lastName: String, $email: String) {
    addMember(firstName: $firstName, lastName: $lastName, email: $email) {
        id
        firstName
    }
}
`;

export const GET_ORG_USERS = gql`
query organization($organizationId: ID!, $showAllUsers: Boolean!) {
    organization(organizationId: $organizationId) {
        id
        officeAdmins {
            id
            firstName
            lastName
            active
        }
        offices {
            id
            name
        }
        adminsAndLeaders(showAllUsers: $showAllUsers) {
            id
            active
            firstName
            lastName
            email
            isOrganizationAdmin
            photoUrl
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;

export const GET_OFFICE_USERS = gql`
query office($officeId: ID!) {
    office(officeId: $officeId) {
        id
        users {
            id
            firstName
            lastName
            email
            photoUrl
            officeMemberships {
                office {
                    id
                    name
                }
                id
                isAdmin
                isCircleLeader
            }
        }
    }
}
`;

export const CREATE_USER_ASSIGN_TO_OFFICES = gql`
mutation createUserAndAssignToOffices($organizationId: ID!, $firstName: String!, $lastName: String!, $email: String!, $photoUrl: String, $isOrganizationAdmin: Boolean!, $offices: [OfficeInput!]) {
    createUserAndAssignToOffices(organizationId: $organizationId, firstName: $firstName, lastName: $lastName, email: $email, photoUrl: $photoUrl, isOrganizationAdmin: $isOrganizationAdmin, offices: $offices) {
        errors
        user {
            id
            firstName
            lastName
            email
            photoUrl
            isOrganizationAdmin
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;

export const EDIT_MEMBERSHIP = gql`
mutation editOfficeMemberships($userId: ID!,  $isOrganizationAdmin: Boolean!, $offices: [OfficeInput!]!) {
    editOfficeMemberships(userId: $userId, isOrganizationAdmin: $isOrganizationAdmin,  offices: $offices) {
        errors
        user {
            id
            firstName
            lastName
            email
            photoUrl
            isOrganizationAdmin
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;