import { gql } from 'apollo-boost';


export const ADD_DOCUMENT = gql`
mutation addDocument($documentType: Int!, $description: String!, $name: String!, $url: String!) {
    addDocument(documentType: $documentType, description: $description, name: $name, url: $url) {
        errors
        document {
            id
            name
            description
            url
            documentType
        }
    }
}
`;


export const GET_S3_UPLOAD_URL_QUERY = gql`
query s3UploadUrl($fileName: String!) {
    s3UploadUrl(fileName: $fileName) 
}
`;

export const DOCUMENTS = gql`
query documents($documentType: Int) {
    documents(documentType: $documentType) {
        id
        description
        documentType
        name
        url       
    }
}
`;


export const DELETE_DOCUMENT = gql`
mutation deleteDocument($documentId: ID!) {
    deleteDocument(documentId: $documentId) {
        deleted
        errors       
    }
}
`;