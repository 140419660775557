import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { useMutation } from '@apollo/react-hooks';

import { DELETE_DOCUMENT, DOCUMENTS } from '../services/graphql';
import Modal from '../../../components/Modal';
import FormErrors from '../../../components/FormErrors';

import styles from '../ResourceCentre.module.scss';
import { getDocumentLabel } from '../../../utils/constants';
import axios from 'axios'

const ResourceDocument = ({ documentResource, sortFilter, currentUser }) => {

    const [confirmDelete, setConfirmDelete] = useState(null);
    const [viewDoc, setViewDoc] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const onDownloadFile = (url) => {
        axios({
            method: 'get',
            url: url,
            responseType: 'blob'
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = downloadUrl;

            // Extract the filename from the Content-Disposition header or use a default name
            const contentDisposition = response.headers['content-disposition'];
            let filename = url.split('/').pop();


            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

        }).catch((error) => {
            console.error('Failed to download file:', error);
        });
    }

    const [deleteDocument, { loading }] = useMutation(DELETE_DOCUMENT, {
        variables: {
            documentId: documentResource.id
        },
        onCompleted: data => {
            if (data && data.deleteDocument.deleted) {
                setConfirmDelete(null);
                return;
            }
            if (data && data.deleteDocument && data.deleteDocument.errors.length > 0) {
                setFormErrors(data.deleteDocument.errors);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: DOCUMENTS, variables: { documentType: sortFilter === 'all' ? null : sortFilter } }]
    });

    return (
        <>
            <li className={styles.docItem}>
                <div><button onClick={() => setViewDoc(documentResource)}>{documentResource.name}</button></div>
                <ul className={styles.docActions}>
                    <li><button onClick={() => onDownloadFile(documentResource.url)}><Icon.ArrowDownCircle /> </button></li>
                    <li> <button onClick={() => setViewDoc(documentResource)}><Icon.Info /></button></li>
                    {currentUser.isOrganizationAdmin && <li> <button onClick={() => setConfirmDelete(documentResource)}><Icon.XCircle /></button></li>}
                </ul>
            </li>

            {confirmDelete && (
                <Modal closeModal={() => setConfirmDelete(null)}>
                    <div className="text-center">
                        <h1>Are you sure?</h1>
                        <button disabled={loading} onClick={() => deleteDocument()} className="btn btn-primary mr-2">Yes</button>
                        <button onClick={() => setConfirmDelete(null)} className="btn btn-muted">No</button>
                        {formErrors && <FormErrors errors={formErrors} />}
                    </div>
                </Modal>
            )}

            {viewDoc && (
                <Modal closeModal={() => setViewDoc(null)}>
                    <div>
                        <h1>{viewDoc.name}</h1>
                        <p><span className='label label-primary'>{getDocumentLabel(viewDoc.documentType)}</span></p>
                        <p className='my-3'>{viewDoc.description}</p>
                        <button onClick={() => setViewDoc(null)} className="btn btn-muted">Close</button>
                    </div>
                </Modal>
            )}

        </>
    )
}

export default ResourceDocument;