import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { useQuery } from '@apollo/react-hooks';
import { CURRENT_USER_QUERY } from '../services/graphql';
import cx from 'classnames';
import Sidebar from '../../../components/Sidebar';
import { useLocation } from 'react-use';
import { usePageVisibility } from 'react-page-visibility';


const LoggedInRouter = ({ children, setOrgData, orgData }) => {

    const [collapseSide, setCollapseSide] = useState(false);
    const location = useLocation();

    const { currentUser, setCurrentUser } = useContext(AuthContext);


    const [pollInterval, setPollInterval] = useState(0);

    const isVisible = usePageVisibility();

    useEffect(() => {
        const startPolling = () => setPollInterval(60000)
        const stopPolling = () => setPollInterval(0)
        isVisible ? startPolling() : stopPolling()
    }, [isVisible]);

    const { error, loading } = useQuery(CURRENT_USER_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ currentUser }) => {
            setCurrentUser(currentUser);
        },
        //pollInterval: 60000,
        pollInterval
    });

    useEffect(() => {
        setOrgData(currentUser.organization);
    }, [currentUser, setOrgData]);


    const getBgColor = () => {
        if (currentUser?.organization?.id === 'T3JnYW5pemF0aW9uLTI=') {
            return '#D9DAD9';
        }
        if (currentUser?.isOrganizationAdmin && !location.pathname.includes('/offices/')) {
            return '#DEECF3'
        }
        return '#F3E4DE';
    }



    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading..</p>
    }

    if (currentUser && currentUser.id) {
        return (
            <div style={{ backgroundColor: getBgColor() }} className={cx('grow-children bodyWrapper')}>
                {currentUser.organization && <Sidebar setCollapseSide={setCollapseSide} collapseSide={collapseSide} />}
                <div className="wrapper">
                    <div className={cx('inner', { 'innerShrink': !collapseSide })}>
                        {children}
                    </div>
                </div>
            </div>

        );
    }
}

export default LoggedInRouter;
