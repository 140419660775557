import { gql } from "apollo-boost";
import { organizationQuery } from "../../../utils/constants";

export const GET_ORGANIZATION = gql`
query organization($organizationId: ID!) {
  organization(organizationId: $organizationId) {
        id
        ${organizationQuery}
    }
}
`;


export const ADD_MENTORING_LOCATION = gql`
mutation addMentoringLocation($organizationId: ID!, $name: String!, $address1: String!, $address2: String, $address3: String, $city: String!, $zipCode: String!) {
    addMentoringLocation(organizationId: $organizationId, name: $name, address1: $address1, address2: $address2, address3: $address3, city: $city, zipCode: $zipCode) {
        errors
        mentoringLocation {
            id
            name
        }
    }
}
`;

export const EDIT_MENTORING_LOCATION = gql`
mutation editMentoringLocation($mentoringLocationId: ID!, $name: String!, $address1: String!, $address2: String, $address3: String, $city: String!, $zipCode: String!) {
    editMentoringLocation(mentoringLocationId: $mentoringLocationId, name: $name, address1: $address1, address2: $address2, address3: $address3, city: $city, zipCode: $zipCode) {
        errors
        mentoringLocation {
            id
            name
        }
    }
}
`;
export const DELETE_MENTORING_LOCATION = gql`
mutation deleteMentoringLocation($mentoringLocationId: ID!) {
    deleteMentoringLocation(mentoringLocationId: $mentoringLocationId) {
        errors
        deleted
    }
}
`


export const DELETE_CATEGORY = gql`
mutation deleteCategory($categoryId: ID!) {
    deleteCategory(categoryId: $categoryId) {
        deleted
        errors
    }
}
`;

export const ADD_CATEGORY = gql`
mutation addCategory($organizationId: ID!, $name: String!, $type: String!) {
    addCategory(organizationId: $organizationId, name: $name, type: $type) {
        category {
            id
            name
            type
        }
        errors
    }
}
`;

export const EDIT_CATEGORY = gql`
mutation editCategory($categoryId: ID!, $name: String!) {
    editCategory(categoryId: $categoryId, name: $name) {
        category {
            id
            name
            type
        }
        errors
    }
}
`;

export const UPDATE_ORG_SESSION_TRESHOLD = gql`
mutation updateOrganizationSessionThreshold($organizationId: ID!, $sessionThreshold: [Int!]!) {
    updateOrganizationSessionThreshold(organizationId: $organizationId, sessionThreshold: $sessionThreshold) {
        organization {
            id
        }
        errors
    }
}
`;