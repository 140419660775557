import React, { memo } from "react";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Plus } from "react-feather";
import FormErrors from "../../../components/FormErrors";

const AddCategory = memo(({ onAddItem, loading, addErrors, type }) => {

    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    });

    const { register, handleSubmit, errors, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const onSubmit = (data) => {
        const { name } = data;
        onAddItem(name).then(() => reset()).catch(console.error);
    };

    const handleDurationChange = ({ target: { value } }) => {
        const val = parseInt(value, 10);
        if (isNaN(val) || value < 1) {
            setValue("name", 1);
        }
    };

    
    return (
        <form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-form__group-row d-flex mb-0">
                {type === 'SessionBreaks' ?
                    <input
                        name="name"
                        placeholder={loading ? 'Saving...' : 'Session number'}
                        className="basic-form__text-box mb-0"
                        ref={register}
                        onChange={handleDurationChange}
                        type='number'
                    />
                    :
                    <input
                        name="name"
                        placeholder={loading ? 'Saving...' : 'Name'}
                        className="basic-form__text-box mb-0"
                        ref={register}
                    />
                }
                <button type='submit' disabled={loading} className="btn btn-blue d-flex align-items-center px-1 ml-1"><Plus /></button>
            </div>
            {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
            {addErrors && <FormErrors errors={addErrors} />}

        </form>
    )
});

export default AddCategory;