import React, { useState, useContext } from 'react';
import Loader from '../../components/Loader';
import { REPORTS, ORGANIZATION_REPORT_DATA } from './services/graphql';
import { useQuery } from '@apollo/react-hooks';
import ImageFadeIn from 'react-image-fade-in';
import CategoriesBreakdown from './components/CategoriesBreakdown';
import CircleLeadersHours from './components/CircleLeadersHours';
import HoursCaseType from './components/HoursCaseType';
import { AuthContext } from '../../services/auth/AuthProvider';
import styles from './Reports.module.scss'
import cx from 'classnames';
import moment from 'moment';

const Reports = () => {

	const { currentUser } = useContext(AuthContext);

	const { data, loading, error } = useQuery(REPORTS, {
		fetchPolicy: 'cache-and-network'
	});

	const currentYear = moment().year();
	const orgStartYear = currentUser.organization && moment(currentUser.organization.createdAt, "YYYY-MM-DD").year();

	const getYears = (startYear, currentYear) => {
		let years = []
		while (startYear <= currentYear) {
			years.push(startYear);
			startYear = startYear + 1;
		}
		return years;
	}

	const { data: orgData, loading: orgLoading, error: orgError } = useQuery(ORGANIZATION_REPORT_DATA, {
		variables: {
			organizationId: currentUser?.organization?.id
		}
	});

	if (loading || orgLoading) return <Loader />;

	if (error || orgError) return <p>{error?.message || orgError?.message}</p>;

	if (data?.reports && orgData?.organization ) {

		const { reports } = data;
		const { organization } = orgData;

		return (
			<>
				<h2>Reports</h2>
				<CategoriesBreakdown isOrgReport={true} reports={reports} />
				<div className="mb-3">
					<div className='row'>
						<div className="col-md-6">
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>Average Timing from Referred to Pre Circle</div>
									<span className='label label-primary'>{reports.averages.timeToPrecircle}</span>
								</div>
							</div>
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>Average Timing from Pre Circle to close</div>
									<span className='label label-primary'>{reports.averages.timeFromPrecircleToClose}</span>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>Average time per case</div>
									<span className='label label-primary'>{reports.averages.timePerCase}</span>
								</div>
							</div>
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>Time logs on each case</div>
									<span className='label label-primary'>{reports.averages.timeLogs}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<CircleLeadersHours
					leaders={organization.leaders.filter(l => l.allCases.length > 0)}
					orgId={organization.id}
					years={getYears(orgStartYear, currentYear)}
				/>
				<HoursCaseType
					organization={organization}
					caseTypes={organization.totalHoursPerCaseTypePerMonth}
				/>

				<div className="mb-3">
					<div className='row'>
						<div className="col-md-6">
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>%  of outcomes reached</div>
									<span className='label label-primary'>{reports.outcomesReachedPercentage || 0}</span>
								</div>
							</div>
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>% of Ethnicities supported</div>
									<span className='label label-primary'>{reports.bamePercentage || 0}</span>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>Number of AP supported</div>
									<span className='label label-primary'>{organization.numberOfAffectedPartiesSupported}</span>
								</div>
							</div>
							<div className='card card-blue mb-2'>
								<div className="card-body d-flex align-items-center justify-content-between">
									<div>Number of RP supported</div>
									<span className='label label-primary'>{organization.numberOfResponsiblePartiesSupported}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card card-blue">
					<div className="card-body">
						<h2>Persona breakdown</h2>
						<ul className={styles.personaList}>
							{reports.personas.map((persona, i) => {
								const personaImage = `../../images/persona/${persona.name}.png`;
								return (
									<li className={styles.personaListItem} key={i}>
										<div className={styles.personaListTitle}>{persona.name}</div>
										<div className={styles.personaListImg}><ImageFadeIn src={personaImage} alt={persona.name} /></div>
										<div className={cx('label label-muted', styles.personaListPerc)}>{persona.value}%</div>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</>
		)
	}
	return null;
}

export default Reports;
