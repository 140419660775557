import { useState, useMemo } from 'react';
import { useDebounce } from './useDebounce';

export const useFilteredList = (items, delay = 0) => {

    const [term, setTerm] = useState('');

    const debouncedTerm = useDebounce(term, delay);

    const handleTermChange = ({ target: { value } }) => setTerm(value);

    const itemsToShow = useMemo(() => {
        return debouncedTerm === '' ? items : items.filter(el => el.name.toLowerCase().includes(debouncedTerm.toLowerCase()));
    }, [debouncedTerm, items]);

    return { itemsToShow, handleTermChange, term, setTerm }
}

