import React from "react";
import { AGREEMENT_STATUS, CIRCLE_MODE, CIRCLE_STATUS } from "../../../../../utils/constants";
import { CheckCircle, PlayCircle, Lock, Slash, AlertTriangle } from 'react-feather';

const ProgressInfo = ({ circle, canApproveSessionRequest }) => {

    const agreementIcon = () => {
        if (circle.status < CIRCLE_STATUS.agreement_repair) {
            return <Lock />
        }
        if (circle.status > CIRCLE_STATUS.agreement_repair && circle.currentAgreement?.status === AGREEMENT_STATUS.accepted) {
            return <CheckCircle />
        }
        if ((circle.currentAgreement?.status === AGREEMENT_STATUS.rejected) || (!circle.currentAgreement && circle.status > CIRCLE_STATUS.agreement_repair) || (circle.currentAgreement?.status !== AGREEMENT_STATUS.accepted && circle.status >= CIRCLE_STATUS.closed_pending_assessment)) {
            return <Slash />
        }

        if (circle.status === CIRCLE_STATUS.agreement_repair) {
            return <PlayCircle />
        }

    }

    const outcomesIcon = () => {
        if (circle.finalAssessment) {
            return circle.finalAssessment.justiceDone ? <CheckCircle /> : <Slash />
        }
        if (circle.status === CIRCLE_STATUS.closed_pending_assessment) {
            return <PlayCircle />
        }
        if (circle.status < CIRCLE_STATUS.closed_pending_assessment) {
            return <Lock />
        }

    }

    return (
        <>
            {canApproveSessionRequest && circle.sessionRequests?.filter(r => !r.approvedOrRejectedAt).length > 0 &&
                <div className="d-flex mb-2">
                    <div className="flex-shrink-0"><AlertTriangle size={20} className="text-danger mr-2"/></div>
                    <div>
                        <h4>Additional Sessions Requested</h4>
                        <p>This case has reached the session limit. Please review and accept or decline on the Notes & Details tab.</p>
                    </div>
                </div>
            }

            <ul>
                <li className='d-flex mb-2'>
                    <div className='mr-1'>{circle.status > CIRCLE_STATUS.started ? <CheckCircle /> : circle.status === CIRCLE_STATUS.started ? <PlayCircle /> : <Lock />}</div>
                    <p className='lead'>Intake and members</p>
                </li>
                <li className='d-flex mb-2'>
                    <div className='mr-1'>{circle.status > CIRCLE_STATUS.opening ? <CheckCircle /> : circle.status === CIRCLE_STATUS.opening ? <PlayCircle /> : <Lock />}</div>
                    <p className='lead mb-1'>{circle.mode === CIRCLE_MODE.mentoring ? 'Mentor Sessions' : 'Opening circle'}</p>
                </li>
                {circle.mode !== CIRCLE_MODE.mentoring &&
                    <li className='d-flex mb-2'>
                        <div className='mr-1'>{agreementIcon()}</div>
                        <p className='lead'>Agreement {circle.mode !== CIRCLE_MODE.mediation && 'and repair'}</p>
                    </li>
                }
                <li className='d-flex mb-2'>
                    <div className='mr-1'>{outcomesIcon()}</div>
                    <p className='lead'>Outcomes</p>
                </li>
            </ul >
        </>
    )
}

export default ProgressInfo;