import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import FormErrors from '../../../components/FormErrors';
import { LOGIN_SCOPES } from '../../../utils/constants';

const LoginUser = ({ emailConfirmed, loginUser, loggingUser, formErrors }) => {

    const schema = Yup.object().shape({
        code: Yup.string().required('Code is required'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });
    const onSubmit = data => {
        const { code } = data;
        loginUser({
            variables: {
                email: emailConfirmed,
                code: code,
                scope: LOGIN_SCOPES.orgAdmin
            }
        });
    }

    return (

        <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-form__group">
                <p>Enter code:</p>
                <input
                    name="code"
                    placeholder="Code"
                    className="basic-form__text-box"
                    ref={register}
                />
                {errors.code && <p className="basic-form__hint">{errors.code.message}</p>}
            </div>
            {formErrors && <FormErrors errors={formErrors} />}

            <button disabled={loggingUser} type="submit" className="btn btn-fullWidth u-display-block">{loggingUser ? 'Loading...' : 'Login'}</button>
        </form>
    );
}

export default LoginUser;
