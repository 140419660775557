import React, { useState } from "react";
import _ from 'lodash';

const TruncateText = ({ str, maxChars = 160 }) => {
    const [showFull, setShowFull] = useState(false);

    if (str.length <= maxChars) {
        return <span className="u-m-right-2 newlines">{str}</span>
    }

    return (
        <span className="u-m-right-2">
            {showFull ? <span className="newlines">{str} <button className='link-underline' onClick={() => setShowFull(false)}>Read less</button></span> :
                <span className="newlines">{_.truncate(str, { 'length': maxChars, 'separator': ' ' })} <button className='link-underline' onClick={() => setShowFull(true)}>Read all</button></span>
            }
        </span>
    )
}

export default TruncateText;