import React, { useContext } from "react";
import styles from './Settings.module.scss'
import { AuthContext } from "../../services/auth/AuthProvider";
import { Link } from "@reach/router";

const Settings = ({ children }) => {

    const { currentUser } = useContext(AuthContext);

    const isActive = ({ isCurrent }) => {
        return {
            className: `text-dark${isCurrent ? ' text-blue bold' : ''}`
        };
    }


    if (currentUser.isOrganizationAdmin && currentUser.organization?.mentoringEnabled) {


        return (
            <>
                <h1>Settings</h1>
                <div className={styles.settings}>
                    <div>
                        <ul className={`${'mb-2 list-group'} ${styles.nav}`}>
                            <li className="list-item list-item--separator-dark">
                                <Link getProps={isActive} to='/settings'>Locations</Link>
                            </li>
                        </ul>


                        <p className="uppercase text-muted-2 bold mb-1">Mentoring</p>
                        <ul className={`${'mb-2 list-group'} ${styles.nav}`}>
                            <li className="list-item list-item--separator-dark">
                                <Link getProps={isActive} to='/settings/session-types'>Session Types</Link>
                            </li>
                            <li className="list-item list-item--separator-dark">
                                <Link getProps={isActive} to='/settings/session-themes'>Session Themes</Link>
                            </li>
                            <li className="list-item list-item--separator-dark">
                                <Link getProps={isActive} to='/settings/cancellation-reasons'>Cancellation Reasons</Link>
                            </li>

                            <li className="list-item list-item--separator-dark">
                                <Link getProps={isActive} to='/settings/session-breaks'>Session Breaks</Link>
                            </li>
                        </ul>


                    </div>
                    <div className="card card-blue">
                        <div className="card-body">
                            {children}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default Settings;

