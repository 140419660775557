import React, { useState, useEffect, useRef, cloneElement } from 'react';
import { Menu } from 'react-feather';
import styles from './Dropdown.module.scss';
import cx from 'classnames';

export const Dropdown = ({
    classNames,
    children,
    titleLeft,
    titleRight,
    leftAligned,
}) => {
    const [visible, setVisible] = useState();

    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const modifiedChildren = React.Children.map(children, child => {
        return cloneElement(child, {
            onClick: (e) => {
                setVisible(false);
                if (child.props.onClick) {
                    child.props.onClick(e); // Call the child's own onClick if it exists
                }
            }
        });
    });

    return (
        <div ref={ref} className={cx(classNames, styles.dropdown)}>
            <button
                className={cx(styles.dropdownBtn, {
                    [styles.dropdownBtnIsActive]: visible,
                })}
                onClick={() => setVisible(!visible)}
            >
                {titleLeft && (
                    <span className={styles.dropdownBtnTitleLeft}>{titleLeft}</span>
                )}
                <Menu />
                {titleRight && (
                    <span className={styles.dropdownBtnTitleRight}>{titleRight}</span>
                )}
            </button>
            {visible && (
                <ul
                    className={cx(styles.dropdownMenu, {
                        [styles.dropdownMenuLeft]: leftAligned,
                    })}
                >
                    {modifiedChildren}
                </ul>
            )}
        </div>
    );
};
