import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import userDefault from '../../../../../../images/profile-default.svg';
import styles from '../../ViewCase.module.scss';
import { GET_PRIVATE_CHATS } from "../../../../services/grapqhl";
import Loader from "../../../../../../components/Loader";
import moment from "moment";

const DirectChats = ({ circleObj }) => {

    const { data, loading, error } = useQuery(GET_PRIVATE_CHATS, {
        variables: {
            circleId: circleObj.id
        },
        //onCompleted: data => console.log(data.circle.privateChats)
    });

    const leaders = [circleObj.leader, circleObj.secondLeader].filter(leader => leader?.user);

    const otherUsers = [
        ...circleObj.affectedParties?.flatMap(party => [party, ...(party.supportingUsers || [])]) || [],
        ...circleObj.responsibleParties?.flatMap(party => [party, ...(party.supportingUsers || [])]) || [],
        ...circleObj.communityMembers || [],
        ...circleObj.participants || [],

    ].filter(member => member?.user);

    const selectedLeader = leaders[0].user.id;

    const [selectedMember, setSelectedMember] = useState(null);

    const getSelectedChat = (chats) => {
        let userChat;
        chats.forEach(chat => {
            if (chat.members.find(member => member.user?.id === selectedLeader) && chat.members.find(member => member?.user?.id === selectedMember)) {
                userChat = chat;
            }
        });
        if (userChat?.messages?.length > 0) {
            return userChat.messages
        }
        return null;
    }

    const disableChatLink = (el, chats) => {
        let disabledLink = true;
        if (selectedLeader) {
            chats.forEach(chat => {
                if (chat.members.find(member => member.user?.id === selectedLeader) && chat.members.find(member => member?.user?.id === el.user.id)) {
                    if (chat.messages.length > 0) {
                        disabledLink = false
                    }
                }
            });
        }
        return disabledLink;
    }



    const getInitialsFromRole = role => {
        switch (role) {
            case 'Leader':
            case 'Second Leader':
                return 'CL';
            case 'Responsible Party':
                return 'RP';
            case 'Affected Party':
                return 'AP';
            case 'Community Member':
                return 'CM';
            case 'Supporting Member':
                return 'SM';
            default:
                return '-'
        }
    }

    let lastUserId = null;
    let currentClass = 'chatEven';

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <Loader />
    }
    if (data?.circle?.id) {

        const { circle } = data;

        return (
            <>
                {circle.privateChats.length > 0 ?
                    <>
                        <div className="d-flex align-items-center flex-row mb-3">
                            <ul className={styles.chatSelect}>
                                {otherUsers.length > 0 && otherUsers.map(member =>
                                    <li onClick={() => setSelectedMember(member.user.id)} key={member.id} className={`${styles.chatHead} ${disableChatLink(member, circle?.privateChats) ? styles.chatHeadDisabled : ''}`}>
                                        <div className={`${styles.chatHeadHolder}  ${selectedMember === member.user.id ? styles.chatHeadHolderActive : ''} `}>
                                            <img className={`${styles.chatHeadImg}`} src={member.user.photoUrl || userDefault} alt='' />
                                        </div>
                                        <p>{`${member.user?.firstName} ${member?.user?.lastName.slice(0, 1)[0]}`}</p>
                                    </li>
                                )}
                            </ul>
                        </div>

                        {circle.privateChats.every(item => item.messages.length === 0) ? <p>No chats</p> :
                            !selectedMember && <p>Select a a member to see chats</p>}
                    </> :
                    <p>No direct chats</p>
                }

                {selectedMember && getSelectedChat(circle.privateChats)?.map(m => {
                    if (m.author.id !== lastUserId) {
                        currentClass = currentClass === 'chatEven' ? 'chatOdd' : 'chatEven';
                        lastUserId = m.author.id;
                    }
                    return (
                        <div key={m.id} className={`d-flex mb-2 ${styles.chat} ${styles[currentClass]}`}>
                            <div className={`text-center ${styles.chatImg}`}>
                                {m.author.photoUrl ?
                                    <img className="mb-1 mx-auto" width={40} height={40} src={m.author.photoUrl} alt='userPhoto' /> :
                                    <img className="mb-1 mx-auto" width={40} src={userDefault} alt='userPhoto' />
                                }
                                {m.authorRole && m.authorRole !== 'Participant' && <span className="label label-muted">{getInitialsFromRole(m.authorRole)}</span>}
                            </div>
                            <div className={`bg-white p-1 px-2 width-full ${styles.chatBody}`}>
                                <div className="newlines">{m.body}</div>
                                <div className={`d-flex mt-1 justify-content-between ${styles.chatAuthor}`}>
                                    <p className="small">{m.author.firstName} {m.author.lastName} </p>
                                    <p className="small">{moment(m.postedAt).format('DD MMM YYYY @ HH.mm')}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }
}

export default DirectChats;