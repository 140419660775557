const chartColors = ['#D94F34', '#DF6B55', '#E68875', '#ECA799', '#F2C3BA', '#F9E3DE'];
//const chartColors = ['#E02626', '#E0C526', '#68E026', '#26E0DB', '#2639E0', '#E026D4'];

export const getColors = (data, fieldName, dataLength) => {
    let colors = [];
    for (let i = 0; i < dataLength; i++) {
        colors.push(data[i][fieldName] > 0 ? chartColors[i % chartColors.length] : '#ccc');
    }
    return colors;
}

const getOrCreateLegendList = (id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');
    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.classList.add('legendWrapper');
        legendContainer.appendChild(listContainer);
    }
    return listContainer;
};

export const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(options.containerID);
        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        items.forEach(item => {
            const li = document.createElement('li');
            li.classList.add('legendItem');
            if (item.fillStyle === '#ccc') {
                li.style.cursor = 'default';
                li.style.pointerEvents = 'none';
            }
            li.onclick = () => {
                const { type } = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };
            // li.onmouseover = () => {
            //     const activeElement = {
            //         datasetIndex: 0,
            //         index: item.index
            //     };
            //     chart.setActiveElements([activeElement]);
            //     chart.tooltip.setActiveElements([activeElement]);
            //     chart.update();
            // }


            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
        // ul.onmouseout = () => {
        //     chart.setActiveElements([]);
        //     chart.tooltip.setActiveElements([]);
        //     chart.update();
        // }
    }
};