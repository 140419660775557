let endpoint = "https://api.restorativ.co";

const hostname = window && window.location && window.location.hostname;

if (hostname === "localhost" || hostname.includes("staging")) {
    endpoint = "https://gentle-atoll-58249.herokuapp.com";
} 

export const API_ENDPOINT = endpoint;

