import React from "react";
import OfficeReportsContent from "../Reports/OfficeReportsContent";


const ViewOffice = ({ officeId }) => {

    return (

        <OfficeReportsContent officeId={officeId} />
    )
}

export default ViewOffice