import React, { useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { GET_CASE, EDIT_CIRCLE_MEMBER, GET_USER_PROFILE_ETHNICITIES } from '../../../services/grapqhl';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Loader from "../../../../../components/Loader";
import FormErrors from '../../../../../components/FormErrors';
import { circleMembership } from "../../../../../utils/constants";

const ParticipantEdit = ({ circle, role, close, member }) => {

    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_USER_PROFILE_ETHNICITIES);

    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        ...(role === circleMembership.affectedParty || role === circleMembership.responsibleParty && {
        }), bame: Yup.string().required('Ethnicity is required'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [editCircleMember, { loading: adding }] = useMutation(EDIT_CIRCLE_MEMBER, {
        onCompleted: data => {
            if (data.editCircleMember.errors && data.editCircleMember.errors.length > 0) {
                setFormErrors(data.editCircleMember.errors);
                return;
            }
            if (data.editCircleMember.circleMember?.id) {
                close()
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE,
                variables: {
                    circleId: circle.id,
                }
            }
        ],
    });

    const onSubmit = data => {
        const { firstName, lastName, address, contactNumber, previousRecord, healthIssues, parentDetails, otherRelevantInformation, bame } = data;
        editCircleMember({
            variables: {
                circleId: circle.id,
                role: +role,
                userData: {
                    email: member.user.email,
                    firstName,
                    lastName,
                    requiresEmail: member.user.requiresEmail,
                    contactNumber: contactNumber !== '' ? contactNumber : null,
                    previousRecord,
                    address: address !== '' ? address : null,
                    healthIssues: healthIssues !== '' ? healthIssues : null,
                    parentDetails: parentDetails !== '' ? parentDetails : null,
                    otherRelevantInformation: otherRelevantInformation !== '' ? otherRelevantInformation : null,
                    bame: +bame,
                }
            }
        });
    }

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.userProfileGendersEthnicitiesAndCountries?.ethnicities) {
        const { userProfileGendersEthnicitiesAndCountries: { ethnicities } } = data;

        return (
            <>
                <h1>Edit participant</h1>

                <form className='form mb-4' onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">

                            <div className="basic-form__group">
                                <label htmlFor='firstName' className='basic-form__text-label'>First name* (required)</label>
                                <input
                                    name="firstName"
                                    placeholder="First name"
                                    type='text'
                                    defaultValue={member.user.firstName}
                                    className="basic-form__text-box"
                                    ref={register}
                                />
                                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="basic-form__group">
                                <label htmlFor='lastName' className='basic-form__text-label'>Last name* (required)</label>
                                <input
                                    name="lastName"
                                    placeholder="Last name"
                                    type='text'
                                    defaultValue={member.user.lastName}
                                    className="basic-form__text-box"
                                    ref={register}
                                />
                                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                            </div>
                        </div>
                    </div>

                    {member.user.requiresEmail &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="basic-form__group">
                                <label htmlFor='contactNumber' className='basic-form__text-label'>Contact phone</label>
                                <input
                                    name="contactNumber"
                                    placeholder="Phone"
                                    className="basic-form__text-box"
                                    type='text'
                                    ref={register}
                                    defaultValue={member.user.userProfile?.contactNumber}
                                />
                            </div>
                        </div>
                    </div>
    }

                    <div className='basic-form__group'>
                        <label htmlFor='bame' className='basic-form__text-label'>Ethnicity* Required</label>
                        <select defaultValue={member.user.userProfile?.bame} ref={register} name="bame" className="basic-form__text-select">
                            <option value=''>Please select</option>
                            {ethnicities.map(el => (
                                <optgroup key={el.id} label={el.group}>
                                    {el.details.map(detail => (
                                        <option key={detail.id} value={detail.id}>{detail.name}</option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                        {errors.bame && <p className='basic-form__hint'>{errors.bame.message}</p>}
                    </div>


                    {(role === circleMembership.affectedParty || role === circleMembership.responsibleParty || role === circleMembership.participant) &&
                        <>
                            <div className="basic-form__group">
                                <label htmlFor='name' className='basic-form__text-label'>Participant address</label>
                                <textarea
                                    name="address"
                                    placeholder="Address"
                                    className="basic-form__text-box"
                                    ref={register}
                                    rows={4}
                                    defaultValue={member.user.userProfile?.address}
                                ></textarea>
                            </div>
                            <div className="basic-form__group basic-form__group--check">
                                <span className="mr-2">Previous Record</span>
                                <input
                                    type="checkbox"
                                    className="switchToggle"
                                    name='previousRecord'
                                    id='previousRecord'
                                    defaultChecked={member.user.userProfile?.previousRecord}
                                    ref={register}
                                />
                                <label className="switchLabel" htmlFor='previousRecord'>&nbsp;</label>
                            </div>

                            <div className="basic-form__group">
                                <label htmlFor='healthIssues' className='basic-form__text-label'>Participant health issues</label>
                                <textarea
                                    name="healthIssues"
                                    placeholder="Health issues"
                                    className="basic-form__text-box"
                                    ref={register}
                                    rows={4}
                                    defaultValue={member.user.userProfile?.healthIssues}

                                ></textarea>
                            </div>

                            <div className="basic-form__group">
                                <label htmlFor='parentDetails' className='basic-form__text-label'>Participant parent details</label>
                                <textarea
                                    name="parentDetails"
                                    placeholder="Parent details"
                                    className="basic-form__text-box"
                                    ref={register}
                                    defaultValue={member.user.userProfile?.parentDetails}
                                    rows={4}
                                ></textarea>
                            </div>

                            <div className="basic-form__group">
                                <label htmlFor='otherRelevantInformation' className='basic-form__text-label'>Other relevant information</label>
                                <textarea
                                    name="otherRelevantInformation"
                                    placeholder="Other details"
                                    className="basic-form__text-box"
                                    ref={register}
                                    defaultValue={member.user.userProfile?.otherRelevantInformation}
                                    rows={4}
                                ></textarea>
                            </div>
                        </>
                    }

                    {formErrors && <FormErrors errors={formErrors} />}

                    <button type="submit" disabled={adding} className="btn">Save</button>
                    <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                </form >
            </>
        )
    }
    return null;
}

export default ParticipantEdit;