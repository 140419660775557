import React from "react";
import { CIRCLE_PHASE } from "../../../../../../utils/constants";
import { GET_CIRCLE_CHATS } from "../../../../services/grapqhl";
import { useQuery } from "@apollo/react-hooks";
import { Clock } from "react-feather";
import moment from "moment";
import userDefault from '../../../../../../images/profile-default.svg';
import { formatDate } from '../../../../../../utils/functions';
import styles from '../../ViewCase.module.scss';
import Loader from "../../../../../../components/Loader";


const CircleChats = ({ circleId }) => {

    const { data, loading, error } = useQuery(GET_CIRCLE_CHATS, {
        variables: {
            circleId: circleId
        }
    })

    let lastUserId = null;
    let currentClass = 'chatEven';

 

    const getInitialsFromRole = role => {
        switch (role) {
            case 'Leader':
                return 'CL';
            case 'Responsible Party':
                return 'RP';
            case 'Affected Party':
                return 'AP';
            case 'Community Member':
                return 'CM';
            default:
                return '-'
        }
    }

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }
    if (data?.circle?.id) {

        const { circle } = data;
        const chats = circle.chats?.find(c => c.circlePhase === CIRCLE_PHASE.opening);

        return (
            <>
                {chats ?
                    <>
                        {chats.messages.length === 0 ?
                            <div className="d-flex align-items-center">
                                <Clock className="mr-1" />
                                <p className="bold">Waiting for Leader to start the chat</p> :

                            </div>
                            :
                            <>
                                <h3 className="mb-1">Opening Circle</h3>
                                <p className="mb-3 small ">Started: {formatDate(chats.startedAt)} {chats.closedAt && <span className="ml-2">Ended: {formatDate(chats.closedAt)}</span>}</p>

                                {chats.messages.map(m => {
                                    if (m.author.id !== lastUserId) {
                                        currentClass = currentClass === 'chatEven' ? 'chatOdd' : 'chatEven';
                                        lastUserId = m.author.id;
                                    }
                                    return (
                                        <div key={m.id} className={`d-flex mb-2 ${styles.chat} ${styles[currentClass]}`}>
                                            <div className={`text-center  ${styles.chatImg}`}>
                                                {m.author.photoUrl ?
                                                    <img className="mb-1 mx-auto" width={40} height={40} src={m.author.photoUrl} alt='userPhoto' /> :
                                                    <img className="mb-1 mx-auto" width={40} src={userDefault} alt='userPhoto' />
                                                }
                                                {m.authorRole && <span className="label label-muted">{getInitialsFromRole(m.authorRole)}</span>}
                                            </div>
                                            <div className={`bg-white p-1 px-2 width-full ${styles.chatBody}`}>
                                                {m.body}
                                                <div className={`d-flex mt-1 justify-content-between ${styles.chatAuthor}`}>
                                                    <p className="small">{m.author.firstName} {m.author.lastName} </p>
                                                    <p className="small">{moment(m.postedAt).format('DD MMM YYYY @ HH.mm')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </>
                        }
                    </>
                    :
                    <div className="d-flex align-items-center">
                        <Clock className="mr-1" />
                        <p className="bold">Circle will start: {circle.openCircleDate} - {circle.openCircleTime}</p>
                    </div>
                }
            </>
        )
    }
}

export default CircleChats;