import React, { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_CIRCLE_MEMBER, GET_CASE, GET_USER_PROFILE_ETHNICITIES } from '../../../services/grapqhl';
import FormErrors from '../../../../../components/FormErrors';
import { CIRCLE_MODE, circleMembership } from '../../../../../utils/constants';
import Loader from '../../../../../components/Loader';

const AddMember = ({ close, circle, role, supportingUserId = null }) => {


    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_USER_PROFILE_ETHNICITIES);

    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().when('requiresEmail', {
            is: (value) => value === true,
            then: Yup.string().required('Email is required'),
            otherwise: Yup.string(),
        }),

        bame: Yup.string().required('Ethnicity is required'),
    });

    const { register, handleSubmit, errors, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            requiresEmail: true
        }
    });

    const [addCircleMember, { loading: adding }] = useMutation(ADD_CIRCLE_MEMBER, {
        onCompleted: data => {
            if (data.addCircleMember.errors && data.addCircleMember.errors.length > 0) {
                setFormErrors(data.addCircleMember.errors);
                return;
            }
            if (data.addCircleMember.circleMember?.id) {
                close();
                reset();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE,
                variables: {
                    circleId: circle.id,
                }
            }
        ],
    });


    const watchRequiresEmail = watch('requiresEmail');;

    const onSubmit = data => {
        const { firstName, lastName, email, requiresEmail, address, contactNumber, previousRecord, healthIssues, parentDetails, otherRelevantInformation, bame } = data;
        console.log('dd', data);
        addCircleMember({
            variables: {
                circleId: circle.id,
                role: +role,
                userData: {
                    ...(requiresEmail && { email }),
                    firstName,
                    requiresEmail,
                    lastName,
                    ...(contactNumber && { contactNumber }),
                    bame: +bame,
                    previousRecord,
                    ...(address && { address }),
                    ...(healthIssues && { healthIssues }),
                    ...(parentDetails && { parentDetails }),
                    ...(otherRelevantInformation && { otherRelevantInformation }),
                    ...(supportingUserId && { supportingUserId })
                }
            }
        });
    }

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.userProfileGendersEthnicitiesAndCountries?.ethnicities) {
        const { userProfileGendersEthnicitiesAndCountries: { ethnicities } } = data;

        return (
            <>
                <div className="row">
                    <div className="col-md-8">
                        {supportingUserId && <p>Support member</p>}
                        <form className='form mb-4' onSubmit={handleSubmit(onSubmit)}>
                            <p className='mb-2 uppercase bold small text-muted-2'>Name & Contact Details</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="basic-form__group">
                                        <label htmlFor='firstName' className='basic-form__text-label'>First name* (required)</label>
                                        <input
                                            name="firstName"
                                            placeholder="First name"
                                            type='text'
                                            className="basic-form__text-box"
                                            ref={register}
                                        />
                                        {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="basic-form__group">
                                        <label htmlFor='lastName' className='basic-form__text-label'>Last name* (required)</label>
                                        <input
                                            name="lastName"
                                            placeholder="Last name"
                                            type='text'
                                            className="basic-form__text-box"
                                            ref={register}
                                        />
                                        {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="basic-form__group basic-form__group--check">
                                <span className="mr-2">Use email address </span>
                                <input
                                    type="checkbox"
                                    className="switchToggle"
                                    name='requiresEmail'
                                    id='requiresEmail'
                                    ref={register}
                                />
                                <label className="switchLabel" htmlFor='requiresEmail'>&nbsp;</label>
                            </div>
                            {watchRequiresEmail ?
                                <>
                                    <p className='mb-2 mt-1'>This member will be required to use the mobile app. They will be sent an email invite with their login credentials.</p>
                                    <div className="basic-form__group">
                                        <label htmlFor='email' className='basic-form__text-label'>Email address</label>
                                        <input
                                            name="email"
                                            placeholder="Email"
                                            type='email'
                                            className="basic-form__text-box"
                                            ref={register}
                                        />
                                        {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="basic-form__group">
                                                <label htmlFor='contactNumber' className='basic-form__text-label'>Contact phone</label>
                                                <input
                                                    name="contactNumber"
                                                    placeholder="Phone"
                                                    className="basic-form__text-box"
                                                    type='text'
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </> : <p className='mb-3'>This member will still be required to use the mobile app. The {circle.mode === CIRCLE_MODE.mentoring ? 'Circle Mentors' : 'Circle Leaders'} will be able to access and pass on this person's unique credentials. </p>
                            }

                            <p className='mb-2 uppercase bold small text-muted-2'>Other data</p>

                            <div className='basic-form__group'>
                                <label htmlFor='bame' className='basic-form__text-label'>Ethnicity* Required</label>
                                <select ref={register} name="bame" className="basic-form__text-select">
                                    <option value=''>Please select</option>
                                    {ethnicities.map(el => (
                                        <optgroup key={el.id} label={el.group}>
                                            {el.details.map(detail => (
                                                <option key={detail.id} value={detail.id}>{detail.name}</option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                                {errors.bame && <p className='basic-form__hint'>{errors.bame.message}</p>}
                            </div>

                            {(role === circleMembership.affectedParty || role === circleMembership.responsibleParty || role === circleMembership.participant) &&
                                <>
                                    <div className="basic-form__group">
                                        <label htmlFor='name' className='basic-form__text-label'>Participant address </label>
                                        <textarea
                                            name="address"
                                            placeholder="Address"
                                            className="basic-form__text-box"
                                            ref={register}
                                            rows={4}
                                        ></textarea>
                                    </div>


                                    <p className='mb-2 uppercase bold small text-muted-2'>Other Data</p>

                                    <div className="basic-form__group basic-form__group--check">
                                        <span className="mr-2">Previous Record</span>
                                        <input
                                            type="checkbox"
                                            className="switchToggle"
                                            name='previousRecord'
                                            id='previousRecord'
                                            ref={register}
                                        />
                                        <label className="switchLabel" htmlFor='previousRecord'>&nbsp;</label>
                                    </div>

                                    <div className="basic-form__group">
                                        <label htmlFor='healthIssues' className='basic-form__text-label'>Participant health issues</label>
                                        <textarea
                                            name="healthIssues"
                                            placeholder="Health issues"
                                            className="basic-form__text-box"
                                            ref={register}
                                            rows={4}
                                        ></textarea>
                                    </div>

                                    <div className="basic-form__group">
                                        <label htmlFor='parentDetails' className='basic-form__text-label'>Participant parent details</label>
                                        <textarea
                                            name="parentDetails"
                                            placeholder="Parent details"
                                            className="basic-form__text-box"
                                            ref={register}
                                            rows={4}
                                        ></textarea>
                                    </div>

                                    <div className="basic-form__group">
                                        <label htmlFor='otherRelevantInformation' className='basic-form__text-label'>Other relevant information</label>
                                        <textarea
                                            name="otherRelevantInformation"
                                            placeholder="Other details"
                                            className="basic-form__text-box"
                                            ref={register}
                                            rows={4}
                                        ></textarea>
                                    </div>
                                </>
                            }

                            {formErrors && <FormErrors errors={formErrors} />}

                            <button type="submit" disabled={adding} className="btn">Save</button>
                            <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                        </form>
                    </div>

                    <div className="col-md-4">
                        {role === circleMembership.responsibleParty ?
                            <div className="p-3 bg-gray">
                                <p className='bold mb-2'>Responsible Party </p>
                                <p>This is usually a person that is responsible for the harm or issue.</p>
                                <p>By default all Responsible Parties will take our empathy test (this can be changed on a person-by-person basis)</p>
                            </div>
                            : role === circleMembership.affectedParty ?
                                <div className="p-3 bg-gray">
                                    <p className='bold mb-2'>Affected Party </p>
                                </div>
                                : role === circleMembership.communityMember &&
                                <div className="p-3 bg-gray">
                                    <p className='bold mb-2'>Community Member </p>
                                    <p>This is usually an outside person that brings another perspective. Common examples are poloce officers, social workers, comapny management etc.</p>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default AddMember;