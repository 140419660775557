import React from 'react';

const LoggedOutRouter = ({ children }) => {

    return (
        <div className='loggedOutBody'>
            <div className="wrapper" >
                {children}
            </div>
        </div>
    )
}

export default LoggedOutRouter;
