import React from "react";
import moment from "moment";
import styles from '../Training.module.scss';
import { ArrowLeft, Star } from "react-feather";

const ViewTraining = ({ item, close }) => {

    const stars = [1, 2, 3, 4, 5];

    const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;


    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <div className="d-flex align-items-center mb-3">
                            <button onClick={close} className="btn-reset mr-1 d-flex align-items-center "><ArrowLeft /></button>
                            <h1 className="mb-0">{item.title}</h1>
                        </div>
                        <p className="mb-1">{moment(item.eventDate).format('DD MMMM YYYY')} @ {item.eventTime}</p>
                        <p className="mb-1">{item.location}</p>
                        <p className="mb-1"><a href={withHttp(item.website)}>{item.website}</a></p>
                        <p className="mb-2">{item.information}</p>
                        {item.isMandatory && <p>{item.isMandatory}</p>}
                        <hr className="separator" />
                        <ul>
                            {item.ratings?.map(review => (
                                <li className="mb-2" key={review.id}>
                                    <p className="small mb-1">{review.author.firstName} {review.author.lastName}</p>
                                    <p className={styles.reviewStars}>
                                        {stars.map(star => {
                                            return <Star color={star <= review.rating ? '#EAA190' : '#F3E4DE'} key={`star${star}`} size="28" />
                                        })}
                                    </p>
                                    <p className="mb-2">{review.comment}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewTraining;