import React, { useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import FormErrors from '../../../components/FormErrors';
import { ADD_DOCUMENT, GET_S3_UPLOAD_URL_QUERY, DOCUMENTS } from '../services/graphql';
import { DOCUMENT_TYPES, getDocumentLabel } from '../../../utils/constants';
import axios from 'axios';

const AddDocument = ({ onCancel, sortFilter }) => {

	const client = useApolloClient();
	const [formErrors, setFormErrors] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);

	const [loader, setLoader] = useState(false);

	const schema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		description: Yup.string().required('Description is required'),
		documentType: Yup.string().required('Type is required'),
	});

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(schema),
		mode: 'onSubmit'
	});

	const onSelectFile = (files) => {
		if (files.length) {
			setSelectedFile(files[0]);
		}
	}

	const [addDocument, { loading }] = useMutation(ADD_DOCUMENT, {
		update: (_, { data }) => {
			if (data && data.addDocument && data.addDocument.document && data.addDocument.document.id) {
				onCancel();
				setLoader(false);
				setSelectedFile(null);
				return
			}
			if (data && data.addDocument && data.addDocument.errors.length > 0) {
				setFormErrors(data.addDocument.errors);
				return;
			}
		},
		awaitRefetchQueries: true,
		refetchQueries: [{ query: DOCUMENTS, variables: { documentType: sortFilter === 'all' ? null : sortFilter } }]
	});

	const onSubmit = data => {
		const { name, description, documentType } = data;
		if (selectedFile) {
			const extension = selectedFile.name.split('.').pop().toLowerCase();
			const filename = `Document-${new Date().getTime()}.${extension}`;
			setLoader(true);

			client.query({
				query: GET_S3_UPLOAD_URL_QUERY,
				variables: {
					fileName: filename
				}
			}).then(r => {
				const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
				axios.put(r.data.s3UploadUrl, selectedFile, options).then(s3r => {
					addDocument({
						variables: {
							name,
							description,
							documentType: +documentType,
							url: r.data.s3UploadUrl.substr(0, r.data.s3UploadUrl.indexOf('?'))
						}
					})
				})
			});
		}
	}



	return (
		<>
			<h3>Add new document</h3>
			<form className='form' onSubmit={handleSubmit(onSubmit)}>
				<div className="basic-form__group">
					<input
						name="name"
						placeholder="Name"
						className="basic-form__text-box"
						ref={register}
					/>
					{errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
				</div>
				<div className="basic-form__group">
					<textarea
						name="description"
						placeholder="Description"
						className="basic-form__text-area"
						ref={register}
						rows="5"
					></textarea>
					{errors.description && <p className="basic-form__hint">{errors.description.message}</p>}
				</div>
				<div className="basic-form__group">
					<select name='documentType' ref={register} className='basic-form__text-select'>
						<option value=''>Please select type</option>
						{
							Object.keys(DOCUMENT_TYPES).map((k) => (
								<option key={`type-${DOCUMENT_TYPES[k]}`} value={DOCUMENT_TYPES[k]}>{getDocumentLabel(DOCUMENT_TYPES[k])}</option>
							))
						}
					</select>
					{errors.documentType && <p className="basic-form__hint">{errors.documentType.message}</p>}
				</div>

				<div className="basic-form__group">
					<input onChange={e => onSelectFile(e.target.files)} type='file' className='basic-form__text-box' />
				</div>
				{loader ? <p>Uploading document ...</p> :
					<button type="submit" disabled={loading || !selectedFile} className="btn btn-fullWidth u-display-block">ADD</button>
				}
				<button type="button" onClick={onCancel} className="btn btn-muted ml-2">Cancel</button>
				{formErrors && <FormErrors errors={formErrors} />}
			</form>
		</>
	)
}

export default AddDocument;
