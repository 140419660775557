import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import { GET_TRAINING_EVENTS } from "./services/graphql";
import TrainingForm from "./components/TrainingForm";
import TrainingItem from "./components/TrainingItem";
import ViewTraining from "./scenes/ViewTraining";
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import { AuthContext } from '../../services/auth/AuthProvider';
import moment from "moment";

const TABS = {
    upcoming: 1,
    past: 2,
}

const Training = () => {

    const { currentUser } = useContext(AuthContext);

    const [addTraining, setAddTraining] = useState(false);
    const [selectedTab, setSelectedTab] = useState(TABS.upcoming);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemView, setItemView] = useState(null);

    const [baseEvents, setBaseEvents] = useState([]);
    const [visibleTraining, setVisibleTraining] = useState(baseEvents);

    const now = moment().startOf('day');

    const { data, loading, error } = useQuery(GET_TRAINING_EVENTS, {
        variables: {
            organizationId: currentUser?.organization?.id
        },
        onCompleted: data => setBaseEvents(data.organization.trainingEvents)
    });

    useEffect(() => {
        if (data?.organization?.trainingEvents && data.organization.trainingEvents !== baseEvents) {
            setBaseEvents(data.organization.trainingEvents)
        }
    }, [data]);


    useEffect(() => {
        if (selectedTab === TABS.upcoming) {
            setVisibleTraining([...baseEvents].filter(item => moment(item.eventDate).isSameOrAfter(now)));
        } else if (selectedTab === TABS.past) {
            setVisibleTraining([...baseEvents].filter(item => moment(item.eventDate).isBefore(now)));
        }
    }, [selectedTab, baseEvents]);


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (itemView) {
        return <ViewTraining item={itemView} close={() => setItemView(null)} />
    }

    if (data?.organization) {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            {itemToEdit || addTraining ? <TrainingForm organizationId={currentUser?.organization?.id} itemToEdit={itemToEdit} close={() => { setItemToEdit(null); setAddTraining(null) }} /> :
                                <>
                                    <div className="d-sm-flex justify-content-between">
                                        <h2>Training</h2>
                                        {(currentUser.isOrganizationAdmin || currentUser.officeMemberships?.find(om => om.isAdmin)) &&
                                            <button onClick={() => setAddTraining(true)} className="btn btn-primary">+ Training</button>
                                        }
                                    </div>

                                    <Tabs withPanel className='mt-2'>
                                        <Tab onClick={() => setSelectedTab(TABS.upcoming)} selected={selectedTab === TABS.upcoming}>Upcoming</Tab>
                                        <Tab onClick={() => setSelectedTab(TABS.past)} selected={selectedTab === TABS.past}>Past</Tab>
                                    </Tabs>
                                    <ul className="mt-3">
                                        {visibleTraining.length > 0 ? visibleTraining.sort((a, b) => a.eventDate.localeCompare(b.eventDate)).map(item => (
                                            <TrainingItem
                                                key={item.id}
                                                setItemView={setItemView}
                                                setItemToEdit={setItemToEdit}
                                                organizationId={currentUser?.organization?.id}
                                                item={item}
                                                currentUser={currentUser}
                                            />
                                        )) : 'No training events'}
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default Training;