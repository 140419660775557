import { ApolloClient } from "apollo-boost";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import ls from "local-storage";
import { LOGGED_IN } from '../scenes/Home/services/graphql';

import { API_ENDPOINT } from './endPoints';

const cache = new InMemoryCache({
    dataIdFromObject: object => `${object.__typename}-${object.id}` || null
});

// TODO: Monkey-patching in a fix for an open issue suggesting that
// `readQuery` should return null or undefined if the query is not yet in the
// cache: https://github.com/apollographql/apollo-feature-requests/issues/1
cache.originalReadQuery = cache.readQuery;
cache.readQuery = (...args) => {
    try {
        return cache.originalReadQuery(...args);
    } catch (err) {
        return undefined;
    }
};

// General link for backend requests
const httpLink = createHttpLink({
    uri: `${API_ENDPOINT}/graphql`,
});

// Chucks in the auth stuff
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = ls('restorativ_admin_token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token || "",
        }
    }
});

// Error handler if request is unauthorised
const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
    ls("errorData", JSON.stringify(response));
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) {
        console.log(`[Network error]: ${networkError.statusCode}`);
        if (networkError.statusCode === 401) {
            cache.writeQuery({
                query: LOGGED_IN,
                data: {
                    forcedLogout: true
                }
            });
            ls.remove('restorativ_admin_token');
        } else {
            cache.writeQuery({
                query: LOGGED_IN,
                data: {
                    error: true
                }
            });
        }
    }
    return forward(operation);
});

// Set up the client
const client = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        errorLink,
        httpLink
    ]),
    cache,
    resolvers: {}
});


cache.writeQuery({
    query: LOGGED_IN,
    data: {
        error: false, // if true show error page
        loggedIn: ls('restorativ_admin_token'),
        forcedLogout: false, // has the user just been kicked out for having a bad token
    }
});

export {
    client
};
