import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import "react-datepicker/dist/react-datepicker.css";
import FormErrors from '../../../../components/FormErrors';
import { CREATE_CIRCLE } from '../../services/grapqhl';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { CIRCLE_MODE } from '../../../../utils/constants';

const CaseStepCreate = ({ onCancel, toLeader, officeId = null }) => {

    const { currentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);

    // if Leader is creating cases (from My Cases page), check if they are a leader in 1 office only and add the case for that office,
    // otherwise, show a office selector for Leaders

    const leaderOfficeMemberships = toLeader?.officeMemberships?.filter(om => om.isCircleLeader);

    const [createCircle, { loading }] = useMutation(CREATE_CIRCLE, {
        onCompleted: data => {
            if (data.createCircle.errors && data.createCircle.errors.length > 0) {
                setFormErrors(data.createCircle.errors);
                return;
            }
            if (data.createCircle.circle && data.createCircle.circle.id) {
                if (toLeader) {
                    navigate(`/cases/${data.createCircle.circle.id}`)
                } else {
                    navigate(`/offices/${officeId}/cases/${data.createCircle.circle.id}`);
                }
            }
        },
        awaitRefetchQueries: true,
    });

    const schema = Yup.object().shape({
        referralSourceId: Yup.string().required('Referral source is required'),
        name: Yup.string().required('Name is required'),
        mode: Yup.string().required('Please select a case mode'),
        caseType: Yup.string().when('mode', {
            is: (value) => +value !== CIRCLE_MODE.mentoring,
            then: Yup.string().required('Case type is required'),
            otherwise: Yup.string(),
        }),
        ...(leaderOfficeMemberships?.length > 1 && { officeForLeader: Yup.string().required('Please select an office') }),
    });

    const { register, handleSubmit, errors, watch } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const watchMode = watch('mode');

    const onSubmit = data => {
        const { mode, caseType, name, officeForLeader, initiativeId, referralSourceId, otherId, conductCircleVirtually } = data;
        const leaderHasOneOffice = (leaderOfficeMemberships?.length === 1 && leaderOfficeMemberships[0]?.office?.id) || null;
        createCircle({
            variables: {
                name,
                ...(initiativeId && { initiativeId: initiativeId }),
                caseType: !caseType ? null : caseType,
                referralSourceId,
                otherId: !otherId ? null : otherId,
                conductCircleVirtually: +mode === CIRCLE_MODE.mentoring ? false : conductCircleVirtually,
                officeId: officeId || officeForLeader || leaderHasOneOffice,
                mode: +mode,
                ...(toLeader && { makeCurrentUserLeader: true })
            }
        });
    }

    return (
        <>
            <h1 className='mb-3'>Create a New Case</h1>
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                {leaderOfficeMemberships?.length > 1 && (
                    <div className="basic-form__group mb-2">
                        <label htmlFor='officeForLeader' className='basic-form__text-label'>Office</label>
                        <select ref={register} name="officeForLeader" className="basic-form__text-select">
                            <option value=''>Select office</option>
                            {leaderOfficeMemberships.map(om =>
                                <option key={om.office.id} value={om.office.id}>{om.office.name}</option>
                            )}
                        </select>
                        {errors.officeForLeader && <p className="basic-form__hint">{errors.officeForLeader.message}</p>}
                    </div>
                )}
                <div className="basic-form__group mb-0">
                    <label htmlFor='name' className='basic-form__text-label'>Case Ref. or Name</label>
                    <input
                        name="name"
                        placeholder="Name or ref to help you identify this case"
                        className="basic-form__text-box"
                        ref={register}
                    />
                    {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                </div>
                <p className='bold mb-3'>Note: Case Number will be automatically generated</p>

                <div className="basic-form__group mb-0">
                    <label htmlFor='name' className='basic-form__text-label'>Mode (Restorative, Mediation, Mentoring)</label>
                    <select ref={register} name="mode" className="basic-form__text-select">
                        <option value=''>Select case mode</option>
                        <option value={CIRCLE_MODE.restorative}>Restorative</option>
                        <option value={CIRCLE_MODE.mediation}>Mediation</option>
                        {currentUser?.organization?.mentoringEnabled &&
                            <option value={CIRCLE_MODE.mentoring}>Mentoring</option>
                        }
                    </select>
                    {errors.mode && <p className="basic-form__hint">{errors.mode.message}</p>}
                </div>
                <p className='bold mb-3'>Note: This setting cannot be changed</p>
                {+watchMode !== CIRCLE_MODE.mentoring &&
                    <>
                    <div className="basic-form__group basic-form__group--check mb-1">
                        <span className="mr-2">Use virtual chatroom</span>
                        <input
                            type="checkbox"
                            className="switchToggle"
                            name='conductCircleVirtually'
                            id='conductCircleVirtually'
                            ref={register}
                            defaultChecked={false}
                        />
                        <label className="switchLabel" htmlFor='conductCircleVirtually'>&nbsp;</label>
                    </div>
                    <p className='small mb-2'>Even if you decide not to use the virtual chatroom the mobile app will be used to manage communications with participants and upload repair agreements</p>
                    </>
                }

                <h2 className='mt-3 mb-1'>Classification</h2>
                <div className='basic-form__group'>
                    <label htmlFor='initiativeId' className='basic-form__text-label'>Initiative (optional)</label>
                    <select ref={register} name="initiativeId" className="basic-form__text-select">
                        <option value=''>Please select</option>
                        {currentUser.organization.initiatives.map(el => (
                            <option key={`initiative-${el.id}`} value={el.id}>{el.name}</option>
                        ))}
                    </select>
                </div>

                <div className='basic-form__group'>
                    <label htmlFor='referralSourceId' className='basic-form__text-label'>Referral Source* Required</label>
                    <select ref={register} name="referralSourceId" className="basic-form__text-select">
                        <option value=''>Please select</option>
                        {currentUser.organization.referralSources.map(el => (
                            <option key={`referralSource-${el.id}`} value={el.id}>{el.name}</option>
                        ))}
                    </select>
                    {errors.referralSourceId && <p className='basic-form__hint'>{errors.referralSourceId.message}</p>}
                </div>
                {currentUser.organization.others.length > 0 &&
                    <div className='basic-form__group'>
                        <label htmlFor='otherId' className='basic-form__text-label'>Others</label>
                        <select ref={register} name="otherId" className="basic-form__text-select">
                            <option value=''>Please select </option>
                            {currentUser.organization.others.map(el => (
                                <option key={`other-${el.id}`} value={el.id}>{el.name}</option>
                            ))}
                        </select>
                    </div>
                }
                {+watchMode !== CIRCLE_MODE.mentoring &&
                    <div className='basic-form__group'>
                        <label htmlFor='caseType' className='basic-form__text-label'>Case Type* Required</label>
                        <select ref={register} name="caseType" className="basic-form__text-select">
                            <option value=''>Please select</option>
                            {currentUser.organization.caseTypes.map(el => (
                                <option key={`caseType-${el.id}`} value={el.id}>{el.name}</option>
                            ))}
                        </select>
                        {errors.caseType && <p className='basic-form__hint'>{errors.caseType.message}</p>}
                    </div>
                }

                {formErrors && <FormErrors errors={formErrors} />}
                <button type="submit" disabled={loading} className="btn">Create Case</button>
                <button type="button" onClick={onCancel} className="btn btn-muted ml-2">Cancel</button>
            </form>
        </>
    )
}

export default CaseStepCreate;