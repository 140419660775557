import React, { useEffect, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';

import { FORCED_LOGOUT, ERROR } from '../services/graphql';
import ErrorPage from '../../../components/ErrorPage';
import { AuthContext } from '../../../services/auth/AuthProvider';

const RouteCapture = ({ children }) => {

    const client = useApolloClient();
    
    const { data: fl } = useQuery(FORCED_LOGOUT);
    const { data: clientError } = useQuery(ERROR);

    const { setCurrentUser } = useContext(AuthContext);

    const appUrl = () => {
        const origin = window?.location?.origin;
        if (origin) {
            return origin;
        } else {
            // Fallback if origin is not supported (older browsers)
            const protocol = window?.location?.protocol;
            const hostname = window?.location?.hostname;
            const port = window?.location?.port;
            return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
        }
        // if (hostname !== "localhost" && !hostname.includes("staging")) {
        //     return "https://eduspots-admin-production-ffeead29553a.herokuapp.com";
        // }
        // if (hostname === "localhost") {
        //     return "http://localhost:3002";
        // }
        // return "https://eduspots-admin-staging-ec32d995a0b4.herokuapp.com";
    }


    const forceLogout = () => {
        ls.remove('restorativ_admin_user');
        ls.remove('restorativ_admin_token');
        client.clearStore();
        setCurrentUser(null);
        window.location.href = appUrl();
    }

    useEffect(() => {
        if (ls('restorativ_admin_user') && ls('restorativ_admin_user') === 'undefined') {
            forceLogout();
        }
    });

    if (fl && fl.forcedLogout) {
        forceLogout();
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    return children || null;
}

export default RouteCapture;