import React, { useState } from "react";
import moment from 'moment';
import EditCircleDetails from "../../../components/AddCase/EditCircleDetails";
import { CIRCLE_MODE, CIRCLE_STATUS } from "../../../../../utils/constants";

const CaseDetails = ({ circle }) => {

    const [edit, setEdit] = useState(false);

    if (circle.status === CIRCLE_STATUS.draft) {
        return <EditCircleDetails onCancel={() => setEdit(false)} circle={circle} />
    }
    return (
        <>

            {edit ? <EditCircleDetails onCancel={() => setEdit(false)} isEdit={true} circle={circle} /> :
                <>
                        <p className='small text-muted'>Reason for referral</p>
                        <p className='mb-2'>{circle.reasonForReferral || '--'}</p>

                        <p className='small text-muted'>Desired outcome</p>
                        <p className='mb-2'>{circle.desiredOutcome || '--'}</p>
                        {circle.mode !== CIRCLE_MODE.mentoring &&
                            <>
                                <p className='small text-muted'>Is this a hate incident?</p>
                                <p className='mb-2'>{circle.hateCrime ? 'Yes' : 'No'}</p>

                                <p className='small text-muted'>Incident Details</p>
                                <p className='mb-2'>{circle.incidentDetails}</p>

                                <p className='small text-muted'>Date of Referral</p>
                                <p className='mb-2'>{moment(new Date(circle.incidentDate)).format('DD MMMM YYYY')}</p>
                            </>}
                        <p className='small text-muted'>Other agency / community involvement</p>
                        <p className='mb-2'>{circle.otherAgencyCommunityInvolvement || '--'}</p>

                        <button className="btn" onClick={() => setEdit(true)}>Edit details</button>
                </>
            }

        </>

    )
}

export default CaseDetails;