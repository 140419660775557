import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { HOURS_PER_MONTH_LEADER_CASE, OFFICE_HOURS_PER_MONTH_LEADER_CASE, GET_OFFICE_TIME_LOGS_CSV } from '../services/graphql';
import Bars from './BarChart';
import Loader from '../../../components/Loader';
import moment from 'moment';
import axios from "axios";


const CircleLeadersHours = ({ orgId, years, leaders, officeId, office = null }) => {

    const [filters, setFilters] = useState({
        leader: null,
        year: moment().year(),
        circleId: null
    })


    const [getMonthHours, { data: reportData, loading: reportLoading }] = useLazyQuery(HOURS_PER_MONTH_LEADER_CASE, {
        fetchPolicy: 'network-only'
    });

    const [getOfficeMonthHours, { data: reportOfficeData, loading: reportOfficeLoading }] = useLazyQuery(OFFICE_HOURS_PER_MONTH_LEADER_CASE, {
        fetchPolicy: 'network-only'
    });

    const [officeTimeLogsCsv, { loading: csvLoading }] = useLazyQuery(GET_OFFICE_TIME_LOGS_CSV, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.officeTimeLogsCsv,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `Timelogs_csv_${office.name}`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    });

    useEffect(() => {
        if (orgId) {
            getMonthHours({
                variables: {
                    organizationId: orgId,
                    circleLeaderId: filters.leader || null,
                    year: +filters.year,
                    circleId: filters.circleId || null
                }
            })
        } else if (officeId) {
            getOfficeMonthHours({
                variables: {
                    officeId: officeId,
                    circleLeaderId: filters.leader || null,
                    year: +filters.year,
                    circleId: filters.circleId || null
                }
            })
        }
    }, [filters]);

    return (
        <div className={`${"card mb-3"} ${orgId ? "card-blue" : ""}`}>
            <div className="card-body">
                <h3>Total number of hours per month</h3>
                <p>Select circle leader</p>

                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <select
                            defaultValue={filters.leader}
                            onChange={(e) => setFilters({ ...filters, leader: e.target.value, circleId: null })}
                            className='basic-form__text-select flex-grow-0 width-auto'
                        >
                            <option value=''>All Circle Leaders</option>
                            {leaders.map(leader => (
                                <option key={leader.id} value={leader.id}>{leader.firstName} {leader.lastName}</option>
                            ))}
                        </select>

                        <select
                            onChange={e => setFilters({ ...filters, year: e.target.value })}
                            defaultValue={filters.year}
                            className='basic-form__text-select ml-1 flex-grow-0 width-auto'
                        >
                            {years.reverse().map(el => (
                                <option key={`year-${el}`} value={el}>{el}</option>
                            ))}
                        </select>
                        {filters.leader && (
                            <select
                                style={{ maxWidth: '200px' }}
                                onChange={e => setFilters({ ...filters, circleId: e.target.value })}
                                defaultValue={filters.case}
                                className='basic-form__text-select ml-1 flex-grow-0 width-auto'
                            >
                                <option value=''>All Cases</option>
                                {office ? (
                                    <>
                                        {leaders.find(leader => leader.id === filters.leader)?.allCases.filter(c => c.office?.id === officeId).map(circle => (
                                            <option key={`case-${circle.id}`} value={circle.id}>{circle.name}</option>
                                        ))}
                                    </>
                                ) :

                                    <>
                                        {leaders.find(leader => leader.id === filters.leader)?.allCases.map(circle => (
                                            <option key={`case-${circle.id}`} value={circle.id}>{circle.name}</option>
                                        ))}</>
                                }

                            </select>
                        )}
                    </div>

                    {
                        officeId && <button
                            disabled={csvLoading}
                            className='btn'
                            onClick={() => officeTimeLogsCsv({
                                variables: {
                                    officeId: officeId,
                                    year: +filters.year,
                                    ...(filters.leader && { circleLeaderId: filters.leader }),
                                    ...(filters.circleId && { circleId: filters.circleId })
                                }
                            })}
                        >Download CSV</button>
                    }

                </div>

                {reportLoading ?
                    <div><Loader /></div> :
                    reportData?.organization?.totalHoursPerMonth && <Bars monthHours={reportData.organization.totalHoursPerMonth} />
                }

                {reportOfficeLoading ?
                    <div><Loader /></div> :
                    reportOfficeData?.office?.totalHoursPerMonth ? <Bars monthHours={reportOfficeData.office.totalHoursPerMonth} /> : <p>No available data</p>
                }
            </div>
        </div>
    )

}

export default CircleLeadersHours;