import React, { useState } from "react";
import { useMutation } from '@apollo/react-hooks';
import { ADD_MENTORING_LOCATION, EDIT_MENTORING_LOCATION, GET_ORGANIZATION } from '../services/graphql';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import FormErrors from '../../../components/FormErrors';

const LocationForm = ({ close, itemToEdit = null, organizationId }) => {

    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        zipCode: Yup.string().required('Zipcode is required'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [addMentoringLocation, { loading }] = useMutation(ADD_MENTORING_LOCATION, {
        onCompleted: data => {
            if (data.addMentoringLocation.errors && data.addMentoringLocation.errors.length > 0) {
                setFormErrors(data.addMentoringLocation.errors);
                return;
            }
            if (data.addMentoringLocation?.mentoringLocation?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId } }]
    });

    const [editMentoringLocation, { loading: editing }] = useMutation(EDIT_MENTORING_LOCATION, {
        onCompleted: data => {

            if (data.editMentoringLocation.errors && data.editMentoringLocation.errors.length > 0) {
                setFormErrors(data.editMentoringLocation.errors);
                return;
            }
            if (data.editMentoringLocation?.mentoringLocation?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_ORGANIZATION, variables: { organizationId } }]
    });

    const onSubmit = data => {
        const { name, address1, address2, address3, city, zipCode } = data;
        if (itemToEdit) {
            editMentoringLocation({
                variables: {
                    mentoringLocationId: itemToEdit.id,
                    name,
                    address1,
                    address2,
                    address3,
                    city,
                    zipCode,
                }
            })
        } else {
            addMentoringLocation({
                variables: {
                    organizationId: organizationId,
                    name,
                    address1,
                    address2,
                    address3,
                    city,
                    zipCode,
                }
            })
        }
    }

    return (
        <>
            <h1>{itemToEdit ? 'Edit' : 'New'} Location</h1>
            <form className='form mb-3 mt-3' onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <label htmlFor='name' className='basic-form__text-label'>Location or Company Name</label>
                    <input
                        name="name"
                        placeholder="e.g. Cafe Nero or UpSkillU Office"
                        className="basic-form__text-box"
                        defaultValue={itemToEdit?.name}
                        ref={register}
                    />
                    {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                </div>

                <div className="basic-form__group">
                    <label htmlFor='address1' className='basic-form__text-label'>Street address</label>
                    <input
                        name="address1"
                        placeholder=""
                        className="basic-form__text-box"
                        defaultValue={itemToEdit?.address1}
                        ref={register}
                    />
                    {errors.address1 && <p className="basic-form__hint">{errors.address1.message}</p>}
                </div>

                <div className="basic-form__group">
                    <label htmlFor='address2' className='basic-form__text-label'>Address 2</label>
                    <input
                        name="address2"
                        placeholder=""
                        className="basic-form__text-box"
                        defaultValue={itemToEdit?.address2}
                        ref={register}
                    />
                </div>

                <div className="basic-form__group">
                    <label htmlFor='address3' className='basic-form__text-label'>Address 3</label>
                    <input
                        name="address3"
                        placeholder=""
                        className="basic-form__text-box"
                        defaultValue={itemToEdit?.address3}
                        ref={register}
                    />
                </div>

                <div className="basic-form__group">
                    <label htmlFor='city' className='basic-form__text-label'>Town/City</label>
                    <input
                        name="city"
                        placeholder=""
                        className="basic-form__text-box"
                        defaultValue={itemToEdit?.city}
                        ref={register}
                    />
                </div>
                {errors.city && <p className="basic-form__hint">{errors.city.message}</p>}


                <div className="basic-form__group">
                    <label htmlFor='zipCode' className='basic-form__text-label'>Postcode</label>
                    <input
                        name="zipCode"
                        placeholder=""
                        className="basic-form__text-box"
                        defaultValue={itemToEdit?.zipCode}
                        ref={register}
                    />
                </div>
                {errors.zipCode && <p className="basic-form__hint">{errors.zipCode.message}</p>}

                <button type="submit" disabled={loading || editing} className="btn btn-blue btn-fullWidth u-display-block">{loading || editing ? 'Saving...' : 'Save location'}</button>
                <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                {formErrors && <FormErrors errors={formErrors} />}
            </form>
        </>
    )
}

export default LocationForm;