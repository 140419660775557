import React, { useState, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import ls from 'local-storage';

import { AuthContext } from '../../services/auth/AuthProvider';
import { LOGIN_INIT, LOGIN_USER } from './services/graphql';
import { LOGGED_IN } from '../Home/services/graphql';
import LoginInit from './components/LoginInit';
import LoginUser from './components/LoginUser';
import logo from '../../images/logo.png';
import styles from './Login.module.scss';
import cx from 'classnames';
import ImageFadeIn from "react-image-fade-in";

const Login = ({location}) => {

    const client = useApolloClient();
    const auth = useContext(AuthContext);
    const [emailConfirmed, setEmailConfirmed] = useState(null);
    const [formInitErrors, setFormInitErrors] = useState([]);
    const [formErrors, setFormErrors] = useState([]);



    const [loginInit, { loading: loggingInit }] = useMutation(LOGIN_INIT, {
        onCompleted: data => {
            if (data?.loginInit?.emailConfirmed) {
                setEmailConfirmed(data.loginInit.emailConfirmed);
                return
            }
            if (data.loginInit.errors && data.loginInit.errors.length > 0) {
                setFormInitErrors(data.loginInit.errors);
                setEmailConfirmed(null);
            }
        },
    });
    const [loginUser, { loading: loggingUser }] = useMutation(LOGIN_USER, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data?.loginUser?.token) {
                ls('restorativ_admin_token', data.loginUser.token);
                ls('restorativ_admin_user', JSON.stringify(data.loginUser.currentUser));
                auth.setCurrentUser(data.loginUser.currentUser);
                client.writeQuery({
                    query: LOGGED_IN,
                    data: {
                        loggedIn: true,
                        forcedLogout: false,
                    }
                });
                return
            }
            if (data?.loginUser.errors && data.loginUser.errors.length > 0) {
                setFormErrors(data.loginUser.errors);
                return
            }
        },
        refetchQueries: () => [{ query: LOGGED_IN }]
    });


    return (
        <div className='row'>
            <div className='col-md-6 mx-auto'>
                <div className={cx('card', styles.login)}>
                    <div className="card-body">
                        <ImageFadeIn className='mx-auto mb-2 d-block' src={logo} width='122' alt='' />
                        <h1 className='h2'>Login</h1>

                        {location.state && location.state.acount_activated && (
                            <h4 className='u-m-base-3'>Account activated succesfully, please login</h4>
                        )}
                        {!emailConfirmed ?
                            <LoginInit
                                loggingInit={loggingInit}
                                loginInit={loginInit}
                                formInitErrors={formInitErrors}

                            />
                            :
                            <LoginUser
                                emailConfirmed={emailConfirmed}
                                loginUser={loginUser}
                                loggingUser={loggingUser}
                                formErrors={formErrors}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login;
