import { gql } from 'apollo-boost';


export const GET_LEADERS= gql`
query leaders {
    leaders {
        id
        active
        firstName
        lastName
        photoUrl
        isOrganizationAdmin
        email
    }
}
`;

export const ADD_LEADER = gql`
mutation addCircleLeaderUser($email: String!, $firstName: String!, $lastName: String!) {
    addCircleLeaderUser(email: $email, firstName: $firstName, lastName: $lastName) {
        errors
        user {
            id
        }
    }
}
`;

export const VIEW_USER = gql`
query user($id: ID!, $archived: Boolean) {
    user(id: $id) {
        id
        firstName
        lastName
        email
        photoUrl
        active
        cases(archived: $archived) {
            id
            name
            createdAt
            caseNumber
            caseType {
                id
                name
            }
            progress
            leader {
                id 
                user {
                    firstName
                    lastName
                    id
                }
                
            }
        }
 
    }
}
`;

export const RESEND_INVITE = gql`
query resendInvite($userId: ID!) {
    resendInvite(userId: $userId) {
        id
    }
}
`;