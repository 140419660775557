import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { EDIT_SESSION_DEBRIEF } from "../../../../services/grapqhl";
import { GET_ORGANIZATION } from "../../../../../Settings/services/graphql";
import { yupResolver } from '@hookform/resolvers';
import FormErrors from '../../../../../../components/FormErrors';

const EditDebrief = ({ session, close, currentUser }) => {

    const { debrief } = session;
    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_ORGANIZATION, {
        variables: {
            organizationId: currentUser?.organization.id
        }
    });

    const [editSessionDebrief, { loading: editing }] = useMutation(EDIT_SESSION_DEBRIEF, {
        onCompleted: data => {
            if (data.editSessionDebrief?.errors?.length > 0) {
                setFormErrors(data.editSessionDebrief.errors);
                return
            }
            if (data.editSessionDebrief?.session?.id) {
                close();
                console.log('done', data);
            }
        }
    })

    const schema = Yup.object().shape({
        actualMentoringSessionThemeId: Yup.string().required('Theme is required'),
        actualContentDescription: Yup.string().required('A description is required'),
        duration: Yup.string().required('Please add duration'),
        healthAndSafetyDescription: Yup.string().when('healthAndSafety', {
            is: (value) => value,
            then: Yup.string().required('A description is required'),
            otherwise: Yup.string(),
        }),
        safeguardingDescription: Yup.string().when('safeguarding', {
            is: (value) => value,
            then: Yup.string().required('A description is required'),
            otherwise: Yup.string(),
        }),
        raceEqualityDiversityDescription: Yup.string().when('raceEqualityDiversity', {
            is: (value) => value,
            then: Yup.string().required('A description is required'),
            otherwise: Yup.string(),
        }),
    });

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            duration: debrief.duration,
            actualMentoringSessionThemeId: debrief.actualMentoringSessionTheme?.id,
            actualContentDescription: debrief.actualContentDescription,
            positiveOutcomes: debrief.positiveOutcomes,
            challenges: debrief.challeges,
            safeguarding: debrief.safeguarding,
            safeguardingDescription: debrief.safeguardingDescription,
            raceEqualityDiversity: debrief.raceEqualityDiversity,
            raceEqualityDiversityDescription: debrief.raceEqualityDiversityDescription,
            healthAndSafety: debrief.healthAndSafety,
            healthAndSafetyDescription: debrief.healthAndSafetyDescription,
            otherIssues: debrief.otherIssues,
        }
    });

    const handleDurationChange = ({ target: { value } }) => {
        const val = parseInt(value, 10);
        if (isNaN(val) || value < 1) {
            setValue("duration", 1);
        }
    };

    const watchToggles = watch(['safeguarding', 'healthAndSafety', 'raceEqualityDiversity']);

    const onSubmit = data => {
        console.log(data);
        const { actualContentDescription, actualMentoringSessionThemeId, duration, positiveOutcomes, challenges, safeguarding, safeguardingDescription, raceEqualityDiversity, raceEqualityDiversityDescription, healthAndSafety, healthAndSafetyDescription, otherIssues } = data;
        editSessionDebrief({
            variables: {
                debriefId: debrief.id,
                actualContentDescription,
                actualMentoringSessionThemeId,
                duration: +duration,
                ...(positiveOutcomes && { positiveOutcomes }),
                ...(challenges && { challenges }),
                safeguarding,
                safeguardingDescription,
                raceEqualityDiversity,
                raceEqualityDiversityDescription,
                healthAndSafety,
                healthAndSafetyDescription,
                ...(otherIssues && { otherIssues })
            }
        });
    }

    if (error) return <p>{error.message}</p>
    if (loading) return <p>Loading</p>

    if (data?.organization?.id) {

        const { organization } = data;
        return (
            <>
                <form className='mb-4' onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic-form__group mb-2">
                        <label htmlFor='duration' className='basic-form__text-label'>Duration</label>
                        <input
                            type="number"
                            name="duration"
                            min={1}
                            ref={register}
                            onChange={handleDurationChange}
                            className="basic-form__text-box"
                        />
                        {errors.duration && <p className="basic-form__hint">{errors.duration.message}</p>}
                    </div>

                    <p className='mb-1 uppercase bold small text-muted-2'>You planned to do:</p>

                    {session.mentoringSessionPlannedTheme &&
                        <>
                            <p className="d-flex align-items-center justify-content-between mb-1">
                                <span className="lead bold">{session.mentoringSessionPlannedTheme?.name}</span>
                                <span className="label label-muted">Planned</span>
                            </p>
                            <p className="newlines">{session.plannedContentDescription}</p>
                        </>
                    }
                    <p className='mb-2 mt-2 uppercase bold small text-muted-2'>You actually did:</p>

                    <div className="basic-form__group mb-2">
                        <select ref={register} name="actualMentoringSessionThemeId" className="basic-form__text-select">
                            <option value=''>Please select</option>
                            {organization.mentoringSessionPlannedThemes.map(theme =>
                                <option key={theme.id} value={theme.id}>{theme.name}</option>
                            )}
                        </select>
                        {errors.actualMentoringSessionThemeId && <p className="basic-form__hint">{errors.actualMentoringSessionThemeId.message}</p>}
                    </div>

                    <div className="basic-form__group mb-2">
                        <label htmlFor='actualContentDescription' className='basic-form__text-label'>Description</label>
                        <textarea
                            rows={3}
                            className="basic-form__text-area"
                            name="actualContentDescription" ref={register}
                        ></textarea>
                        {errors.actualContentDescription && <p className="basic-form__hint">{errors.actualContentDescription.message}</p>}
                    </div>

                    <p className='mb-2 uppercase bold small text-muted-2'>Outcomes and challenges</p>

                    <div className="basic-form__group mb-2">
                        <label htmlFor='positiveOutcomes' className='basic-form__text-label'>Positive Outcomes</label>
                        <textarea
                            rows={3}
                            className="basic-form__text-area"
                            name="positiveOutcomes" ref={register}
                        ></textarea>
                    </div>

                    <div className="basic-form__group mb-2">
                        <label htmlFor='positiveOutcomes' className='basic-form__text-label'>Challenges</label>
                        <textarea
                            rows={3}
                            className="basic-form__text-area"
                            name="challenges" ref={register}
                        ></textarea>
                    </div>
                    <p className='mb-1 uppercase bold small text-muted-2'>Other Details</p>
                    <p className="small mb-2">Were any of the following issues raised during or since the last session?</p>

                    <div className="basic-form__group basic-form__group--check mb-1 mt-2">
                        <span className="mr-2">Health And Safety</span>
                        <input
                            type="checkbox"
                            className="switchToggle"
                            name='healthAndSafety'
                            id='healthAndSafety'
                            ref={register}
                        />
                        <label className="switchLabel" htmlFor='healthAndSafety'>&nbsp;</label>
                    </div>
                    {watchToggles.healthAndSafety && (
                        <>
                            <div className="basic-form__group mb-1">
                                <label htmlFor='healthAndSafetyDescription' className='basic-form__text-label'>Health And Safety Issues</label>
                                <textarea
                                    rows={3}
                                    className="basic-form__text-area"
                                    name="healthAndSafetyDescription" ref={register}
                                ></textarea>
                            </div>
                            {errors.healthAndSafetyDescription && <p className="basic-form__hint">{errors.healthAndSafetyDescription.message}</p>}

                        </>
                    )}


                    <div className="basic-form__group basic-form__group--check mb-1 mt-2">
                        <span className="mr-2">Race, Equality, Diversity</span>
                        <input
                            type="checkbox"
                            className="switchToggle"
                            name='raceEqualityDiversity'
                            id='raceEqualityDiversity'
                            ref={register}
                        />
                        <label className="switchLabel" htmlFor='raceEqualityDiversity'>&nbsp;</label>
                    </div>
                    {watchToggles.raceEqualityDiversity && (
                        <>
                            <div className="basic-form__group mb-1">
                                <label htmlFor='raceEqualityDiversityDescription' className='basic-form__text-label'>Race, Equality, Diversity Issues</label>
                                <textarea
                                    rows={3}
                                    className="basic-form__text-area"
                                    name="raceEqualityDiversityDescription" ref={register}
                                ></textarea>
                            </div>
                            {errors.raceEqualityDiversityDescription && <p className="basic-form__hint">{errors.raceEqualityDiversityDescription.message}</p>}

                        </>
                    )}


                    <div className="basic-form__group basic-form__group--check mb-1 mt-2">
                        <span className="mr-2">Safeguarding</span>
                        <input
                            type="checkbox"
                            className="switchToggle"
                            name='safeguarding'
                            id='safeguarding'
                            ref={register}
                        />
                        <label className="switchLabel" htmlFor='safeguarding'>&nbsp;</label>
                    </div>
                    {watchToggles.safeguarding &&
                        <>
                            <div className="basic-form__group mb-1">
                                <label htmlFor='safeguardingDescription' className='basic-form__text-label'>Safeguarding Issues</label>
                                <textarea
                                    rows={3}
                                    className="basic-form__text-area"
                                    name="safeguardingDescription" ref={register}
                                ></textarea>
                            </div>
                            {errors.safeguardingDescription && <p className="basic-form__hint">{errors.safeguardingDescription.message}</p>}

                        </>
                    }
                    <p className='mb-1 uppercase bold small text-muted-2 mt-3'>Other Issues</p>
                    <p className="small mb-2">Any other issues that need addressing before the next session?</p>
                    <div className="basic-form__group mb-2">
                        <textarea
                            rows={3}
                            className="basic-form__text-area"
                            name="otherIssues" ref={register}
                        ></textarea>
                    </div>
                    {formErrors && <FormErrors errors={formErrors} />}
                    <button disabled={editing} type="submit" className="btn mr-2">{editing ? 'Saving...' : 'Save edits'}</button>
                    <button type="button" className="btn btn-muted" onClick={close}>Cancel</button>
                </form>
            </>
        )
    }
}

export default EditDebrief;