import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_CIRCLE_TIME_LOGS } from "../../../../services/grapqhl";
import Loader from "../../../../../../components/Loader";
import moment from "moment";
import styles from '../../ViewCase.module.scss';

const TimeLogs = ({ circleId }) => {

    const [selectedLeader, setSelectedLeader] = useState(null);

    const { data, loading, error } = useQuery(GET_CIRCLE_TIME_LOGS, {
        variables: {
            circleId: circleId
        }
    })

    const getTotalTime = circle => {
        const totalTime = circle.logs?.reduce((a, b) => a + b.duration, 0) || 0;
        return Number.isInteger(totalTime) ? totalTime : totalTime.toFixed(2);
    };

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.circle?.id) {

        const { circle } = data;

        const handleLeaderChange = ({ target: { value } }) => setSelectedLeader(value);

        const logsToShow = !selectedLeader ? circle.logs : circle.logs.filter(log => log.user.id === selectedLeader);


        return (
            <>
                <h1>Total time <span className="mx-1 label label-primary bold">{getTotalTime(circle)}</span> hours</h1>

                <div className="mb-2">
                    <select value={selectedLeader || ''} onChange={handleLeaderChange} className="basic-form__text-select width-auto pr-4">
                        <option value=''>All Case Leaders</option>
                        {circle.leader?.user && <option value={circle.leader.user.id}>{circle.leader.user.firstName} {circle.leader.user.lastName}</option>}
                        {circle.secondLeader?.user && <option value={circle.secondLeader.user.id}>{circle.secondLeader.user.firstName} {circle.secondLeader.user.lastName}</option>}
                    </select>
                </div>
                {logsToShow.length > 0 ?
                    <ul className="list-group">
                        {logsToShow.map(log =>
                            <li className="list-item list-item--separator-dark" key={log.id}>
                                <p>{`${log.user?.firstName}  ${log.user?.lastName}`}</p>
                                <div className="ml-auto">
                                    <span className="mr-2"> {log.logDate && moment(log.logDate).format('DD MMMM YYYY')}</span>
                                    <span className={`label label-primary ${styles.logLabel}`}>
                                        {isFinite(log.duration) ? Number((log.duration).toFixed(2)) : '0'}
                                    </span>
                                </div>
                            </li>
                        )}
                    </ul>
                    : <p>No logs added {selectedLeader && <>by the selected leader</>}</p>
                }
            </>
        )
    }
}

export default TimeLogs